import { makeStyles } from '@material-ui/styles';
// import tinycolor from 'tinycolor2';

export default makeStyles(theme => ({

    sectionRoot: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginBottom: '40px',
    },

    sectionContent: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
       // backgroundColor: '#FBFBFB',
        minHeight: '40px',
        borderRadius: '6px',
       // padding: '16px',
    },

    tabRoot: {
        width: '100%',
        '& .MuiTabs-fixed': {

            '& .MuiTabs-flexContainer': {
                justifyContent: 'flex-end',
                '& .MuiTab-root': {
                    minWidth: '32px',
                    '&[aria-selected="true"]': {
                       color: '#0c0644',
                    },
                },
            },

            '& .MuiTabs-indicator': {
                backgroundColor: '#0c0644',
            },
        },
    },

    tabContent: {
        display: 'flex',
        width: '100%',
    },

    input: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '24px',
        maxWidth: '280px',
        width: '100%',
        '& span': {
            maxWidth: '150px !important',
        },
    },

    logoSpace: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',

        '& .image-item': {
            marginBottom: '8px !important',
        },

        '& .MuiButtonBase-root': {
            marginRight: '0px !important',
        },

        '& .MuiBox-root': {
            justifyContent: 'center !important',
        },
    },

    logoTitle: {
        display: 'flex',
        justifyContent: 'left',
        width: '100%',
    },

    colorPicker: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        maxWidth: '280px',
        padding: '0px 16px 0px 16px',
        borderRadius: '4px',
        height: '48px',
        border: '1px solid #B2B2B2',
        marginTop: '24px',
    },

    colorPickerText: {
        marginRight: '16px',
    },

    bannerGallery: {
        display: 'flex',
        height: '240px',
        width: '100%',
        justifyContent: 'center',
    },

    bannerList: {
        display: 'flex',
        width: '100%',
        marginTop: '16px',
        maxHeight: '224px',
        overflowY: 'auto',
        overflowX: 'hidden',
        border: '1px solid #B2B2B2',
        borderRadius: '4px',
    },

    bannerListItems: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
    },

    bannerItem: {
        width: '130px',
        height: '40px',
        overflow: 'hidden',
        marginTop: '12px',
        marginBottom: '12px',
        boxSizing: 'border-box',
        borderRadius: '4px',

        '&:hover': {
            outline: '1px solid  #2FB1D1',
            cursor: 'pointer',
        },

        '&[data-selected="true"]': {
            outline: '1px solid #0c0644EE',
        },
    },

    bannerItemImage: {
        height: '40px',
    },
}));
