import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
    Grid,
} from '@material-ui/core';

import useStyles from './imageResizer.styles';

import {
    handleDotMouseDown,
    handleDotMouseUp,
} from './engineListeners';

function ImageResizer (props) {
    const {
        shape,
    } = props;

    const classes = useStyles({
        shape,
    });

    function renderRoundDots () {
        return (
            <React.Fragment>
                <div
                    className={ classNames(['top-left', classes.dot])}
                    onMouseDown={(e) => handleDotMouseDown(e, 'top-left')}
                    onMouseUp={(e) => handleDotMouseUp(e, 'top-left')}
                    > </div>

                <div
                    className={ classNames(['top-right', classes.dot])}
                    onMouseDown={(e) => handleDotMouseDown(e, 'top-right')}
                    onMouseUp={(e) => handleDotMouseUp(e, 'top-right')}
                    > </div>

                <div
                    className={ classNames(['bottom-left', classes.dot])}
                    onMouseDown={(e) => handleDotMouseDown(e, 'bottom-left')}
                    onMouseUp={(e) => handleDotMouseUp(e, 'bottom-left')}
                    > </div>
                <div
                    className={ classNames(['bottom-right', classes.dot])}
                    onMouseDown={(e) => handleDotMouseDown(e, 'bottom-right')}
                    onMouseUp={(e) => handleDotMouseUp(e, 'bottom-right')}
                    > </div>
            </React.Fragment>
        );
    }

    function renderRectDots () {
        return (
            <React.Fragment>
                   <div
                    className={ classNames(['top-left', classes.dot])}
                    onMouseDown={(e) => handleDotMouseDown(e, 'top-left')}
                    onMouseUp={(e) => handleDotMouseUp(e, 'top-left')}
                    > </div>
                <div
                    className={ classNames(['top-center', classes.dot])}
                    onMouseDown={(e) => handleDotMouseDown(e, 'top-center')}
                    onMouseUp={(e) => handleDotMouseUp(e, 'top-center')}
                    > </div>
                <div
                    className={ classNames(['top-right', classes.dot])}
                    onMouseDown={(e) => handleDotMouseDown(e, 'top-right')}
                    onMouseUp={(e) => handleDotMouseUp(e, 'top-right')}
                    > </div>
                <div
                    className={ classNames(['mid-left', classes.dot])}
                    onMouseDown={(e) => handleDotMouseDown(e, 'mid-left')}
                    onMouseUp={(e) => handleDotMouseUp(e, 'mid-left')}
                    > </div>
                <div
                    className={ classNames(['mid-right', classes.dot])}
                    onMouseDown={(e) => handleDotMouseDown(e, 'mid-right')}
                    onMouseUp={(e) => handleDotMouseUp(e, 'mid-right')}
                    > </div>

                <div
                    className={ classNames(['bottom-left', classes.dot])}
                    onMouseDown={(e) => handleDotMouseDown(e, 'bottom-left')}
                    onMouseUp={(e) => handleDotMouseUp(e, 'bottom-left')}
                    > </div>

                <div
                    className={ classNames(['bottom-center', classes.dot])}
                    onMouseDown={(e) => handleDotMouseDown(e, 'bottom-center')}
                    onMouseUp={(e) => handleDotMouseUp(e, 'bottom-center')}
                    > </div>
                <div
                    className={ classNames(['bottom-right', classes.dot])}
                    onMouseDown={(e) => handleDotMouseDown(e, 'bottom-right')}
                    onMouseUp={(e) => handleDotMouseUp(e, 'bottom-right')}
                    > </div>
            </React.Fragment>
        );
    }

    function renderDotsList () {
        if (shape === 'round') {
            return renderRoundDots();
        }
        return renderRectDots();
    }

    function renderDots () {
        return (
            <div
                id={'resizer-dots'}
                className={classes.dots}
            >
                { renderDotsList() }
            </div>
        );
    }

    return (
        <Grid
            id='editor-resizer'
            className={classes.resizerRoot}
        >
            { renderDots() }
        </Grid>
    );
}

ImageResizer.propTypes = {
    shape: PropTypes.func,
    bounds: PropTypes.any,
    target: PropTypes.any,
};

ImageResizer.defaultProps = {
    shape: 'rect',
    bounds: {},
    target: {},
};

export default ImageResizer;
