import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Grid,
} from '@material-ui/core';

import useStyles from './styles';
import {
    updateWidgetSettings,
    // updateWidgetSetting,
} from './widgetUtil';

const urlSource = {
    prod: 'https://testing-sync.ecal.com/?apiKey=iO4vouUk2ZQ1FvdIKIrlZGdTSztVhkTcBhdRJkIxLX86242a0b&mode=preview',
    // prod: 'https://192.168.1.119:2090/?&apiKey=iO4vouUk2ZQ1FvdIKIrlZGdTSztVhkTcBhdRJkIxLX86242a0b&mode=preview',
};

const defalutLogo = 'https://d27vx6ekipa712.cloudfront.net/assets/app/logo-round.svg';

function getWidgetURL () {
    return urlSource.prod;
}

function WidgetPreview (props) {
    const {
        styleData,
    } = props;
    const classes = useStyles();

    const iframeRef = useRef();
    const [previewURL] = useState(getWidgetURL());

    function updateWidgetStyle () {
        // console.log('Style Data: ', styleData);

        const settings = {
            primary_button_background: styleData.secondaryColour,
            header_background: styleData.headingColour,
            logo: styleData.logo || defalutLogo,
            logo_shadow: styleData.logoShadow,
            logoShadow: styleData.logoShadow,
            logoShape: styleData.logoShape,
            logo_shape: styleData.logoShape || 'round',
        };

        if (styleData.cssFile) {
            settings.customCssUrl = styleData.cssFile;
        }

        if (styleData.bannerType) {
            settings.bannerType = styleData.bannerType;
            settings.bannerURL = styleData.bannerURL;
            settings.bannerColour = styleData.bannerColour;
            if (styleData.bannerType === 'image' && !styleData.bannerURL) {
                settings.bannerType = 'solid';
                settings.bannerColour = '#FFFFFF';
            }
        }

        // console.log('updateWidgetStyle(): ', settings);
        updateWidgetSettings(iframeRef.current.contentWindow, settings);
    }

    function updateLanguage () {
        const settings = {
            language: styleData.language,
        };
        updateWidgetSettings(iframeRef.current.contentWindow, settings);
    }

    useEffect(() => {
        // console.log('WidgetPreview.useEffect(): ', styleData);
        updateWidgetStyle();
    }, [styleData]);

    const widgetAppListener = (e) => {
        if (e.data.action === 'kickstartComplete') {
            updateWidgetStyle();
        }
    };

    useEffect(() => {
        window.addEventListener('message', widgetAppListener);
        return () => {
            window.removeEventListener('message', widgetAppListener);
        };
    }, []);

    useEffect(() => {
        updateLanguage();
    }, [styleData.language]);

    /*
    function widgetURL () {
        // this should be different on different platform...
        // "https://staging-sync.ecal.com/button/v1/ecal.html?_=1632800773590&amp;widgetRef=widget-demo&amp;apiKey=6ea0955297341b6b22f516a42177979d55821c6d7217b&amp;docReferrer=https%3A%2F%2Fstaging-admin.ecal.com%2F%3FsuperAdminUser%3D10865&amp;isPreview=true&amp;language=en&amp;"
        return getWidgetURL();
    }
    */

    return (
        <Grid className={classes.fullSize}>
            <iframe
                onLoad={() => {
                    // eslint-disable-next-line no-undef
                    updateWidgetStyle();
                }}

                ref={iframeRef}
                className={classes.widgetPreviewMode}
                id="widget-demo"
                scrolling="no"
                frameBorder={0}
                src={previewURL}
            >
            </iframe>
        </Grid>
    );
}

WidgetPreview.propTypes = {
    styleData: PropTypes.any,
};

WidgetPreview.defaultProps = {
   styleData: {},
};

export default WidgetPreview;
