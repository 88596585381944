import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
    root: {
       display: 'flex',
       width: '100%',
       height: '100%',
    },

    canvas: {
        width: '100%',
        height: '100%',
        cursor: 'move',
    },
}));
