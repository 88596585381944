import { useEffect, useState } from 'react';

import {
    initEditorUI,
    cleanEditorUI,
    createImage,
} from './elements';

import {
    defaultLimits,
} from './engineCore';

import {
    initState,
    getRelativeBounds,
    initContentState,
} from './engineState';

import {
    renderWorkspace,
} from './engineRenderer';

import {
    attachMouseEvents,
    dispatchChangeEvent,
} from './engineListeners';

function useImageEditor (props) {
    const {
        imageSource,
        onChangeComplete,
        shape,
        limits = defaultLimits,
    } = props;

    const [image, setImage] = useState(null);

    useEffect(() => {
        console.log('useImageEditor.useEffect()');
        window.editorScaling = false;
        window.editorOnChangeComplete = onChangeComplete;
        initEditorUI({
            shape,
            onUIMounted,
        });

        return () => {
            cleanEditorUI();
        };
    }, []);

    useEffect(() => {
        window.editorUI.shape = shape;
        initState(getRelativeBounds(window.editorUI.workspace), image, shape);
        initContentState(image);
        drawEditor();
    }, [shape]);

    useEffect(() => {
        updateImageBySource();
    }, [imageSource]);

    async function updateImageBySource () {
        if (imageSource) {
            const { image } = await createImage(imageSource);
            setImage(image);
            initState(getRelativeBounds(window.editorUI.workspace), image, shape);
            initContentState(image);
            console.log('updateImageBySource():has image', window.editorState.content);
            drawEditor();
        }
    }

    async function onUIMounted (uis) {
        window.editorLimits = limits;
        initState(getRelativeBounds(uis.workspace), image, shape);
        if (imageSource) {
            await updateImageBySource();
        } else {
            drawEditor();
        }
        attachMouseEvents(window.editorUI);
    }

    function drawEditor () {
        renderWorkspace(window.editorState, window.editorUI);
        dispatchChangeEvent();
    }

    return {
        image,
    };
}

export default useImageEditor;
