import { getResizeFunc } from './engineStateResizer';
import {
    mouseMoveState,
    resizerDotsState,
/*    center, */
} from './engineState';

/*
 * defaultLimits are use to restrict the movement images and canvas
 */
export const defaultLimits = {
    minScale: 0.5,
    maxScale: 3,
    scaleChangeRate: 0.001,

    minX: 0,
    minY: 0,
    minCanvasWidth: 80,
    minCanvasHeight: 80,
    maxCanvasWidth: 400,
    maxCanvasHeight: 400,
};

/*
 * manageStateAndRender is the entry point where state
 * executed during the mouseMove of Resizer
 */

export function manageResizerStateAdjustments (p) {
    const {
        event,
        state,
        shape,
        limits,
    } = p;

    // console.log(' manageResizerStateAdjustments() Params:: ', p);

    state.mouse = mouseMoveState({
        event,
        mouse: state.mouse,
        target: state.eventSource,
        shape,
    });

    const resizeFunc = getResizeFunc(state.eventSource);

    const stack = {
        resizer: { ...state.resizer },
        content: { ...state.content },
    };

    const {
        resizer,
        content,
    } = resizeFunc({
        ...state,
        resizer: state.resizerOrigin,
        content: state.contentOrigin,
        limits,
    });

    if (resizer.width <= limits.minCanvasWidth) {
        resizer.width = limits.minCanvasWidth;
        resizer.x = stack.resizer.x;
        content.x = stack.content.x;
    } else if (resizer.width >= limits.maxCanvasWidth) {
        resizer.width = limits.maxCanvasWidth;
        resizer.x = stack.resizer.x;
        content.x = stack.content.x;
    }

    if (resizer.height <= limits.minCanvasHeight) {
        resizer.height = limits.minCanvasHeight;
        resizer.y = stack.resizer.y;
        content.y = stack.content.y;
    } else if (resizer.height >= limits.maxCanvasHeight) {
        resizer.height = limits.maxCanvasHeight;
        resizer.y = stack.resizer.y;
        content.y = stack.content.y;
    }

    return {
        resizer,
        resizerDots: resizerDotsState(resizer),
        canvas: resizer,
        // crosshair: center(resizer, { width: 24, height: 24 }),
        crosshair: { ...resizer },
        content,
    };
}

export function captureCanvasURI () {
    if (window?.editorUI?.canvas) {
        return window?.editorUI?.canvas.toDataURL('image/png');
    }
    return '';
}

export function resizeDataURI (canvas, targetWidth, targetHeight) {
    // Create an off-screen canvas
    const tempCanvas = document.createElement('canvas');
    const tempCtx = tempCanvas.getContext('2d');
    tempCanvas.width = targetWidth;
    tempCanvas.height = targetHeight;
    tempCtx.drawImage(canvas, 0, 0, targetWidth, targetHeight);
    return tempCanvas.toDataURL('image/png');
}

export function getScaledDataURI () {
    const shape = window.editorUI.shape;
    const canvas = window.editorUI.canvas;

    if (shape === 'round') {
        return resizeDataURI(canvas, 160, 160);
    }

    const scale = 160 / canvas.height;
    const scaledWidth = canvas.width * scale;
    return resizeDataURI(canvas, scaledWidth, 160);
}
