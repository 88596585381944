import { makeStyles } from '@material-ui/styles';
// import tinycolor from 'tinycolor2';

export default makeStyles(theme => ({
    input: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '24px',
        maxWidth: '280px',
        width: '100%',
        '& span': {
            maxWidth: '150px !important',
        },
    },

    logoSpace: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid #B2B2B2',
        minHeight: '120px',
        borderRadius: '4px',
        '& .image-item': {
            marginBottom: '8px !important',
        },

        '& .MuiButtonBase-root': {
            marginRight: '0px !important',
        },

        '& .MuiBox-root': {
            justifyContent: 'center !important',
        },
    },

    logoDisplay: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
    },

    logoTitle: {
        display: 'flex',
        justifyContent: 'left',
        width: '100%',
        marginBottom: '8px',
    },

    imageSpace: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: 'calc(100% - 72px)',
    },

    logoImage: ({ width, height }) => {
        return {
            cursor: 'pointer',
            maxWidth: width,
            maxHeight: height,
            margin: '12px',
        };
    },

    imageControls: {
        display: 'flex',
        flexDirection: 'row',
        width: '72px',
    },

    iconButton: {
        width: '36px',
        height: '36px',
    },
}));
