import { useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
/*
    Gallery URL
    Blue Banner: https://testing-ecal-publisher-assets.s3.amazonaws.com/6282_14370/6282_14370_1738645034525832981.png
    Purple Banner: https://testing-ecal-publisher-assets.s3.amazonaws.com/6282_14370/6282_14370_1738645095272936733.png
*/

import {
    useButtonsStore,
    useUserStore,
} from '../../../../store/models';

export const bannersSource = [
    {
        label: 'Blue',
        value: 'blue',
        url: 'https://ecal-dynamic-assets.s3.us-east-1.amazonaws.com/assets/app/banner-animation-blue.svg',
    },
    {
        label: 'Purple',
        value: 'purple',
        url: 'https://ecal-dynamic-assets.s3.us-east-1.amazonaws.com/assets/app/banner-animation-purple.svg',
    },
];

const bannerTypes = {
    solid: 0,
    image: 1,
    dynamic: 2,
};

function useBannerHook (props) {
    const {
        styleData,
        // setStyleData,
    } = props;
    const user = useUserStore();
    const buttonStore = useButtonsStore();
    const [tabValue, setTabValue] = useState(0);
    const [bannerSelected, setBannerSelected] = useState('');
    const [publisher, setPublisher] = useState(user.publisher);

    useEffect(() => {
        baseTabOnStyleData();
    }, []);

    useEffect(() => {
        baseTabOnStyleData();
    }, [styleData]);

    function baseTabOnStyleData () {
        if (styleData.bannerType) {
            setTabValue(bannerTypes[styleData.bannerType]);
            if (styleData.bannerType === 'solid') {
                if (isEmpty(buttonStore.styleData)) {
                    buttonStore.styleData = { ...styleData };
                }
            }
        }
    }

    useEffect(() => {
        setPublisher(user.publisher);
    }, [user.observed.publisher]);

    function getBannerType (value) {
        const types = [
            'solid', // as in solid color
            'image',
            'dynamic',
        ];

        if (value >= types.length) {
            return 'solid';
        }

        return types[value];
    }

    function tabChange (e, newValue) {
        setTabValue(newValue);
        const type = getBannerType(newValue);

        if (!buttonStore.styleData) {
            buttonStore.styleData = { ...styleData };
        }
        buttonStore.applyBannerImage({
            ...buttonStore.styleData,
            bannerType: type,
        }, type);
    }

    function isBannerSelected (v) {
        return bannerSelected === v;
    }

    return {
        tabChange,
        tabValue,
        bannerSelected,
        setBannerSelected,
        isBannerSelected,
        buttonStore,
        publisher,
    };
}

export default useBannerHook;
