import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
    Grid,
    IconButton,
    Tooltip,
} from '@material-ui/core';

import {
    PublishOutlined as CreateIcon,
    Brightness1 as CircleIcon,
    Crop32 as RectIcon,
    FiberSmartRecord as ShadowIcon,
} from '@material-ui/icons';

import {
    Typography,
} from '../../../../components/Wrappers';

import Button from '../../../../commons/ButtonProgress';
import Dialog from '../../../../commons/dialog/Dialog';

import useStyles from './imageSelectWizard.styles';
import useImageCrop from './useImageCrop';
import ImageCrop from '../../../../components/ImageCrop';
import ImageEditor from '../../../../components/ImageEditor';
function ImageSelectWizard (props) {
    const {
        styleData,
        setStyleData,
        open,
        onClose,
        title,
        type,
    } = props;

    const {
        image,
        selectImage,
        imageSelection,
        onChangeComplete,
        setShape,
        shape,
        transform,
        uploadCanvasDataURI,
        uploading,
        shadow,
        setShadow,
    } = useImageCrop({
        wizardOpen: open,
        styleData,
        setStyleData,
        type,
        onClose,
    });

    const classes = useStyles({
        imageWidth: '100px',
        imageHeight: '60px',
        shape,
        type,
    });

    function renderBanner () {
        return (<Grid
            className={classes.bannerImagePreview}
        >
             <ImageCrop
                imageSource={image}
                onChangeComplete={onChangeComplete}
                shape={'rect'}
                transform={transform}
            />
        </Grid>);
    }

    function imagePreview () {
        if (type === 'banner') {
            return renderBanner();
        }

        if (shape === 'round') {
            return (<Grid
                id='preview-box'
                className={classes.roundImagePreview}
            >
                <ImageEditor
                    imageSource={image}
                    onChangeComplete={onChangeComplete}
                    shape={shape}
                    shadow={shadow}
                />
            </Grid>);
        }

        return (
            <Grid
                id="preview-box"
                className={classes.rectImagePreview}
            >
                <ImageEditor
                    imageSource={image}
                    onChangeComplete={onChangeComplete}
                    shape={shape}
                    shadow={shadow}
                />
            </Grid>
            /* </Grid> */
        );
    }

    function bannerButtonBar () {
        return (
            <Grid
                className={classes.buttonBarBanner}
            >
                <Grid className={classes.barSpaceBanner}>
                    <Tooltip title={'Browse'}>
                        <IconButton
                            id="upload-button"
                            color={'primary'}
                            onClick={() => {
                                selectImage();
                            }}
                            className={classes.iconButton}
                        >
                            <CreateIcon
                                className={classes.deleteIcon}
                                fontSize={'medium'} />
                        </IconButton>
                    </Tooltip>

                    <Button
                        onClick={() => {
                            uploadCanvasDataURI();
                        }}
                        variant={'outlined'}
                        color={'normal'}
                        showProgress={uploading}
                        label={'upload'}
                    >
                    </Button>
                </Grid>
            </Grid>
        );
    }

    function logoButtonBar () {
        return (
            <Grid
                className={classes.buttonBar}
            >
                <Grid className={classes.barSpace}>
                    <Grid
                        className={classes.barGroup}
                        style={{
                            width: '40px',
                        }}
                        >
                        <Tooltip title={'Browse'}>
                                <IconButton
                                    id="browse-button"
                                    color={'primary'}
                                    onClick={() => {
                                        selectImage();
                                    }}
                                    className={classes.iconButton}
                                >
                                    <CreateIcon
                                        className={classes.icon}
                                        fontSize={'medium'} />
                                </IconButton>
                            </Tooltip>
                    </Grid>

                    <Grid className={classes.barGroupSpace} >
                        <Grid className={classNames([classes.barGroup])}>
                            <Tooltip title={'Round'}>
                                <IconButton
                                    color={'primary'}
                                    onClick={() => {
                                        setShape('round');
                                    }}
                                    className={classes.iconButton}
                                    data-selected={ shape === 'round' ? 'true' : 'false'}
                                >
                                    <CircleIcon
                                        className={classes.icon}
                                        fontSize={'medium'} />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title={'Rectangle / Square'}>
                                <IconButton
                                    color={'primary'}
                                    onClick={() => {
                                        setShape('rect');
                                    }}
                                    className={classes.iconButton}
                                    data-selected={ shape === 'rect' ? 'true' : 'false'}
                                >
                                    <RectIcon
                                        className={classes.icon}
                                        fontSize={'medium'} />
                                </IconButton>
                            </Tooltip>
                        </Grid>

                        <Tooltip title={'Shadow'}>
                            <IconButton
                                id="shadow-button"
                                color={'primary'}
                                onClick={() => {
                                    setShadow(!shadow);
                                }}
                                className={classes.iconButton}
                                data-selected={ shadow ? 'true' : 'false'}
                            >
                                <ShadowIcon
                                    className={classes.icon}
                                    fontSize={'medium'} />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid
                        className={classes.barGroup}
                        style={{
                            width: '40px',
                        }}
                        >
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    function renderButtonShapeTab () {
        if (type === 'banner') {
            return bannerButtonBar();
        }
        return logoButtonBar();
    }

    function getDialogRoot () {
        if (type === 'banner') {
            return classes.dialogRootBanner;
        }
        return classes.dialogRoot;
    }

    function getDialogContentBox () {
        if (type === 'banner') {
            return classes.dialogContentBoxBanner;
        }
        return classes.dialogContentBox;
    }

    function renderActionBar () {
        if (type === 'banner') {
            return (<React.Fragment></React.Fragment>);
        }

        return (
            <Grid
                className={classes.actionBar}
            >
                <Button
                    onClick={() => {
                        uploadCanvasDataURI();
                    }}
                    variant={'outlined'}
                    color={'normal'}
                    showProgress={uploading}
                    label={'upload'}
                >
                </Button>
            </Grid>
        );
    }

    function getPreviewContainerClass () {
        if (type === 'banner') {
            return classes.bannerPreviewContainer;
        }

        return classes.previewContainer;
    }

    function renderTextContent () {
        if (type === 'banner') {
            return 'Upload JPG, PNG, or SVG images up to 200kb in size, and preferably 596px (width) x 84px (height) in dimension.';
        }

        if (shape === 'rect') {
            return 'Upload JPG, PNG, or SVG images up to 200kb in size, and preferably 2 (width) : 1 (height) in ratio.';
        }

        return 'Upload JPG, PNG, or SVG images up to 200kb in size, and preferably 1 (width) :1 (height) in ratio.';
    }

    function renderTextGuide () {
        return (
            <Grid className={classes.adUnit}>
                    <Typography
                    variant={'body2'}
                    weight={'light'}
                    className={classes.adUnitText}
                >
                    { renderTextContent() }
                </Typography>
            </Grid>
        );
    }

    return (
        <React.Fragment>
            <Dialog
                withCloseIcon={true}
                containerMaxWidth={'680px'}
                className={getDialogRoot()}
                containerClass={getDialogContentBox()}
                openDialog={open}
                buttonSecondaryFlag={false}
                buttonPrimary={'Save'}
                buttonPrimaryVariant={'outlined'}
                buttonPrimaryColor={'normal'}
                buttonPrimaryFlag={false}

                handleAgree={() => {
                    onClose();
                }}
                title={title}
                dialogContextText={''}
                showAction={false}
            >
                <Grid className={classes.dialogContent}>
                    <input
                        id="logoWizardSelect"
                        className={classes.fileSelect}
                        type="file"
                        accept="image/*"
                        onChange={(input) => {
                            imageSelection(input);
                        }}
                    />
                    <Grid
                        className={getPreviewContainerClass()}
                    >
                        { imagePreview() }
                    </Grid>
                    { renderTextGuide() }
                    { renderButtonShapeTab() }
                    { renderActionBar() }
                </Grid>
            </Dialog>
        </React.Fragment>
    );
};

ImageSelectWizard.propTypes = {
    setStyleData: PropTypes.func,
    uploadButtonLogo: PropTypes.func,
    title: PropTypes.string,
    styleData: PropTypes.object,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    type: PropTypes.string,
};

ImageSelectWizard.defaultProps = {
    setStyleData: () => {},
    uploadButtonLogo: () => {},
    styleData: {},
    open: false,
    onClose: () => {},
    title: 'Logo Styles',
    type: 'logo', // logo or banner,
};

export default ImageSelectWizard;
