import { useState, useEffect } from 'react';
import {
    handleImageSelect,
} from './logoWizardUtils';

import { DEFAULT_IMAGE } from '../../../../commons/imageUploader/AdvertisingDefaultImage';
import useImageUpload from '../../../hooks/useImageUploadDynamicStore';

import DefaultRoundLogo from '../../../../components/ImageCrop/roundLogo';
import DefaultRectLogo from '../../../../components/ImageCrop/rectLogo';

import { AssetType } from '../../../../utils/constants';
import { useButtonsStore } from '../../../../store/models';
import { dataURIToFile } from '../../../../utils/image';

function useImageCrop (props) {
    const {
        styleData,
        setStyleData,
        type,
        wizardOpen,
        onClose,
    } = props;

    const buttonsStore = useButtonsStore();
    const [image, setImage] = useState(DEFAULT_IMAGE);
    const [defaultImage, setDefaultImage] = useState(DEFAULT_IMAGE);
    const [capturedImage, setCapturedImage] = useState(null);
    const [transform, updateTransform] = useState({ x: 0, y: 0, scale: 1 });
    const [uploading, setUploading] = useState(false);
    const [isImageBrowsed, setIsImageBrowsed] = useState(false);
    const [shadow, _setShadow] = useState(true);
    const [shape, _setShape] = useState('round');

    function setShape (v) {
        _setShape(v);
        /*
        setStyleData({
            ...styleData,
            logoShape: v,
        });
        */
    }

    function setShadow (v) {
        _setShadow(v);
        setStyleData({
            ...styleData,
            logoShadow: v,
        });
    }

    function setImageURL (url) {
        setUploading(false);

        if (!wizardOpen) {
            return;
        }
        console.log('setImageURL() URL:', url);
        if (type === 'banner') {
            console.log('setImageURL() set as Banner: ', url);
            setStyleData({
                ...styleData,
                bannerURL: url,
            });
        } else {
            console.log('setImageURL() set as Logo');
            setStyleData({
                ...styleData,
                logo: url,
            });
        }
        if (uploading) {
            onClose();
        }
    };

    function shouldCheckSize () {
        if (type === 'banner') {
            return false;
        }
        return true;
    }

    const {
        image: buttonLogo,
        upload: uploadButtonLogo,
        imageError: buttonLogoError,
        // deleteImage: deleteButtonLogo,
    } = useImageUpload({
        assetType: AssetType.BUTTON_LOGO,
        observableDataSource: buttonsStore.observed.assets.buttonLogo,
        consumableDataSource: buttonsStore.assets.buttonLogo,
        customDefaultImage: defaultImage,
        setStateFunc: setImageURL,
        store: buttonsStore,
        uploadMethod: 'uploadWidgetAssets',
        shouldCheckFilesize: shouldCheckSize(),
    });

    useEffect(() => {
        if (styleData.logoShape) {
            setShape(styleData.logoShape);
        }
        _setShadow(styleData.logoShadow);
    }, []);

    useEffect(() => {
        if (styleData.logoShape) {
            setShape(styleData.logoShape);
        }
        _setShadow(styleData.logoShadow);
    }, [styleData.logoShape]);

    useEffect(() => {
        console.log('File Size error:', buttonLogoError);
    }, [buttonLogoError]);

    useEffect(() => {
        updateImageByDefaults();
    }, [shape]);

    function updateImageByDefaults () {
        if (!isImageBrowsed && type !== 'banner') {
            if (styleData.logo) {
                setImage(styleData.logo);
                return;
            }

            if (shape === 'round') {
                setDefaultImage(DefaultRoundLogo);
                if (!styleData.logo) {
                    setImage(DefaultRoundLogo);
                    /*
                    setTransform({
                        scale: 2.129999999999985,
                        y: -6.719999999999331,
                        x: -13.719999999999331,
                    });
                    */
                }
            } else if (shape === 'rect') {
                setDefaultImage(DefaultRectLogo);
                if (!styleData.logo) {
                    setImage(DefaultRectLogo);
                    /*
                    setTransform({
                        x: -22.51999999999842,
                        y: -11.719999999999331,
                        scale: 2.129999999999985,
                    });
                    */
                }
            }
        }
    }

    function setTransform (p) {
        if (p) {
            updateTransform(p);
        } else {
            updateTransform({
                x: 0,
                y: 0,
                scale: 1,
            });
        }
    }

    function selectImage () {
        const btn = document.querySelector('#logoWizardSelect');
        btn.click();
    }

    async function imageSelection (e) {
        setIsImageBrowsed(true);
        const imageURI = await handleImageSelect(e.target);
        setImage(imageURI);
    }

    function onChangeComplete (e) {
        if (e.transform) {
            setTransform(e.transform);
        }
        setCapturedImage(e.imageURI);
    }

    async function uploadCanvasDataURI () {
        setUploading(true);
        setStyleData({
            ...styleData,
            logoShape: shape,
        });
        const file = await dataURIToFile(capturedImage, 'button-logo.png');
        uploadButtonLogo([{
            file: file,
            data_url: capturedImage,
        }]);
    }

    return {
        buttonLogo,
        image,
        selectImage,
        imageSelection,
        capturedImage,
        shape,
        setShape,
        onChangeComplete,
        transform,
        uploadCanvasDataURI,
        uploading,
        shadow,
        setShadow,
    };
}

export default useImageCrop;
