import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
    Grid,
} from '@material-ui/core';

import useStyles from './banner.styles';
import {
    bannersSource,
} from './useBannerHook';

function BannerGallery (props) {
    const {
        styleData,
        setStyleData,
        isBannerSelected,
        setBannerSelected,
    } = props;

    const [bannersList] = useState(bannersSource);
    const classes = useStyles({
    });

    function renderBannerItems () {
        return bannersList.map((item, index) => {
            return (
                <Grid
                    className={classes.bannerItem}
                    onClick={(v) => {
                        setBannerSelected(item.value);
                        setStyleData({
                            ...styleData,
                            bannerURL: item.url,
                        });
                    }}
                    data-selected={isBannerSelected(item.value)}
                    key={`banner-${index}`}
                >
                    <img
                        className={classes.bannerItemImage}
                        src={item.url}
                        alt={`Banner ${item.value}`}
                        />
            </Grid>
            );
        });
    }

    return (<Grid
        className={classes.bannerList}
    >
        <Grid className={classes.bannerListItems}>
            { renderBannerItems() }
        </Grid>
    </Grid>);
}

BannerGallery.propTypes = {
    setStyleData: PropTypes.func,
    styleData: PropTypes.object,
    bannerSelected: PropTypes.string,
    setBannerSelected: PropTypes.any,
    isBannerSelected: PropTypes.func,
};

BannerGallery.defaultProps = {
    setStyleData: () => {},
    styleData: {},
    bannerSelected: '',
    setBannerSelected: () => {},
    isBannerSelected: () => {
        return false;
    },
};

export default BannerGallery;
