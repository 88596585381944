/* This widget Util  is specifically made for Admin to Interact with Widget */

export function updateWidgetSettings (contentWindow, settings) {
    const p = {
        type: 'SETTINGS_CONFIGURE',
        payload: {
            message: settings,
            source: 'updateSettings',
            token: 'ECAL_WIDGET_POST_MESSAGE',
        },
        data: {
            settings: settings,
        },
        action: 'previewUpdateWidgetSettings',
    };
    contentWindow.postMessage(p, '*');
    // const param = JSON.stringify(p);
    // contentWindow.postMessage(param, '*');
}

export function updateWidgetSetting (contentWindow, field, value) {
    const p = {
        type: 'SETTING_CONFIGURE',
        payload: {
            message: {
                field,
                value,
            },
            source: 'updateSetting',
            token: 'ECAL_WIDGET_POST_MESSAGE',
        },
        data: {
            settings: {
                [field]: value,
            },
        },
        action: 'previewUpdateWidgetSettings',
    };
    const param = JSON.stringify(p);
    contentWindow.postMessage(param, '*');
}
