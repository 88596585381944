import { makeStyles } from '@material-ui/styles';
// import tinycolor from 'tinycolor2';

export default makeStyles(theme => ({

    root: {
        backgroundColor: '#ffffff',
        padding: 24,
        '& .rct-node-icon': {
            color: '#0c0644!important',
        },
    },

    dialogRoot: () => {
        return {
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            '& .MuiDialog-container': {
                minHeight: '600px',
                minWidth: '800px',
                maxWidth: '800px',

                '& .MuiDialog-paper': {
                    margin: '0px',
                    width: '800px',
                    maxWidth: '800px',
                },
            },

            '& .MuiDialogContent-root': {
                padding: '8px 16px !important',
            },

            '& .MuiDialogActions-root': {
                padding: '16px !important',
            },
        };
    },

    dialogContentBox: {
        width: '800px',
        maxWidth: '800px',
        minHeight: '400px',
    },

    dialogRootBanner: () => {
        return {
            display: 'flex',
            width: '100%',
            minWidth: '680px',
            flexDirection: 'column',
            justifyContent: 'center',
            '& .MuiDialog-container': {
                minHeight: '600px',
                '& .MuiDialog-paper': {
                    minWidth: '680px',
                },
            },

            '& .MuiDialogContent-root': {
                padding: '8px 16px !important',
            },

            '& .MuiDialogActions-root': {
                padding: '16px !important',
            },
        };
    },

    dialogContentBoxBanner: {
        width: '680px',
        minHeight: '280px',
    },

    dialogContent: ({ type }) => {
        const style = {
            display: 'flex',
            width: '100%',
            minHeight: '300px',
            flexDirection: 'column',
            justifyContent: 'center',

            '& .MuiDialogContent-root': {
                padding: '8px 16px !important',
            },

            '& .MuiDialogActions-root': {
                padding: '16px !important',
            },
        };

        if (type === 'banner') {
            style.justifyContent = 'flex-start';
            style.minHeight = '220px';
        }
        return style;
    },

    cropContainer: {
        width: '200px',
        height: '200px',
        overflow: 'hidden',
        position: 'relative',
        background: '#FFFFFF',

        '& .reactEasyCrop_Container': {
            width: '100%',
            height: '100%',
            backgroundColor: '#FFFFFF',

            '& .reactEasyCrop_CropArea': {
                width: '100% !important',
                height: '100% !important',
            },

            '& .reactEasyCrop_Contain': {
                margin: '0 0',
            },

        },

        [theme.breakpoints.up('sm')]: {
          // height: 400,
        },
    },

    fileSelect: {
        display: 'none',
        width: 0,
        height: 0,
    },

    previewContainer: {
        display: 'block',
        width: '100%',
        height: '400px',
        position: 'relative',
        border: '1px solid #F1F1F1',
    },

    roundImagePreview: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        border: '1px solid #A3A3A3',
        // borderRadius: '100px',
        backgroundColor: '#F7F7F7',
    },

    rectImagePreview: {
        position: 'absolute',
        overflow: 'visible',
        width: '100%',
        height: '100%',
        border: '1px solid #A3A3A3',
        borderRadius: '0px',
        backgroundColor: '#F7F7F7',
    },

    rectSpace: {
        position: 'absolute',
        width: '482px',
        height: '202px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    bannerPreviewContainer: {
        display: 'flex',
        width: '100%',
        height: '120px',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid #A3A3A3',
        backgroundColor: '#F7F7F7',
    },

    bannerImagePreview: {
        position: 'absolute',
        width: '598px',
        height: '84px', // the preview size correspond to the size of the image crop
        border: '1px dashed #73737366',
        backgroundColor: '#FFFFFF',
        boxSizing: 'content-box',
    },

    buttonBar: {
        display: 'flex',
        marginTop: '24px',
        width: '100%',
        height: '40px',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '12px',
    },

    actionBar: {
        display: 'flex',
        marginTop: '16px',
        width: '100%',
        height: '40px',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },

    barSpace: {
        display: 'flex',
        width: '100%',
        height: '40px',
        justifyContent: 'space-between',
        alignItems: 'center',
    },

    buttonBarBanner: {
        display: 'flex',
        marginTop: '0px',
        width: '100%',
        height: '40px',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '8px',
    },

    barSpaceBanner: {
        display: 'flex',
        width: '594px',
        height: '40px',
        justifyContent: 'space-between',
        alignItems: 'center',
    },

    barGroup: {
        display: 'flex',
        height: '40px',
        justifyContent: 'center',
        alignItems: 'center',
    },

    barGroupSpace: {
        width: '200px',
        display: 'flex',
        height: '40px',
        justifyContent: 'space-between',
        alignItems: 'center',
    },

    iconButton: {
        width: '36px',
        height: '36px',

        '&[data-selected="true"]': {
            backgroundColor: '#85CDED',
        },
    },

    icon: {
    },

    adUnit: {
        display: 'flex',
        width: '96%',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },

    adUnitText: ({ type }) => {
        return {
            fontSize: '12px !important',
            marginLeft: type === 'banner' ? '8px' : '8px',
            marginBottom: 4,
            marginTop: 4,
        };
    },
}));
