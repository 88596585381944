import { isEmpty } from 'lodash';

import {
    mouseMoveCanvasState,
    contentTranslateState,
    contentScaleState,
    prepareStateEditing,
    finalizeScaling,
    snapToCenterState,
} from './engineState';

import {
    manageResizerStateAdjustments,
    getScaledDataURI,
} from './engineCore';

import {
    renderUpdates,
} from './engineRenderer';

const EditorActions = {
    CANVAS_RESIZE: 'canvas-resize',
    CONTENT_MOVE: 'content-move',
    CONTENT_SCALE: 'content-scale',
};

export function handleDotMouseDown (e, target) {
    // initialize the active editor state

    if (!window.editorAction) {
        window.editorAction = EditorActions.CANVAS_RESIZE;
        prepareStateEditing({ e, target });
    }
}

export function handleDotMouseUp (e, target) {
    if (window.activeEditorState && window.editorAction === EditorActions.CANVAS_RESIZE) {
        snapToCenter();
        window.editorState = {
            ...window.activeEditorState,
        };
        window.activeEditorState = null;
        delete window.editorAction;
        dispatchChangeEvent();
    }
}

function snapToCenter () {
    const newState = {
        ...window.activeEditorState,
        ...snapToCenterState({
            state: window.activeEditorState,
        }),
    };
    window.activeEditorState = newState;
    renderUpdates();
}

export function handleWorkspaceMouseMove (event) {
    if (!isEmpty(window.activeEditorState) && window.editorAction === EditorActions.CANVAS_RESIZE) {
        const updates = manageResizerStateAdjustments({
            event,
            state: { ...window.activeEditorState },
            ui: window.editorUI,
            shape: window.editorUI.shape,
            limits: window.editorLimits,
        });

        window.activeEditorState = {
            ...window.activeEditorState,
            ...updates,
        };
        renderUpdates();
    }
}

export function handleCanvasMouseDown (e) {
    if (!window.editorAction) {
        window.editorAction = EditorActions.CONTENT_MOVE;
        prepareStateEditing({ e });
        updateWithCanvasEvent(e);
    }
}

export function handleCanvasMouseUp (e) {
    if (window.activeEditorState && window.editorAction === EditorActions.CONTENT_MOVE) {
        updateWithCanvasEvent(e);

        window.editorState = {
            ...window.activeEditorState,
        };
        window.activeEditorState = null;
        delete window.editorAction;
        dispatchChangeEvent();
    }
}

export function handleCanvasMouseMove (e) {
    if (!isEmpty(window.activeEditorState) && window.editorAction === EditorActions.CONTENT_MOVE) {
        e.preventDefault();
        e.stopPropagation();
        updateWithCanvasEvent(e);
    }
}

export function updateWithCanvasEvent (e) {
    window.activeEditorState.mouse = mouseMoveCanvasState({
        mouse: window.activeEditorState.mouse,
        event: e,
    });
    window.activeEditorState.content = contentTranslateState(window.activeEditorState);
    renderUpdates();
}

export function handleCanvasWheel (e) {
    e.stopPropagation();
    e.preventDefault();

    if (!window.editorAction) {
        window.editorAction = EditorActions.CONTENT_SCALE;
        prepareStateEditing();
    }

    if (window.editorAction === EditorActions.CONTENT_SCALE) {
        window.activeEditorState.content = contentScaleState({
            event: e,
            state: window.activeEditorState,
            limits: window.editorLimits,
            ui: window.editorUI,
        });
        window.editorState = { ...window.activeEditorState };
        renderUpdates();
        clearTimeout(window.editorWheelDebounceTO);
        function wheelEnded () {
            finalizeScaling();
            dispatchChangeEvent();
            delete window.editorAction;
            window.activeEditorState = null;
        }
        window.editorWheelDebounceTO = setTimeout(wheelEnded, 200);
    }
}

export function attachMouseEvents (uis) {
    function attach () {
        if (uis.workspace) {
            uis.workspace.addEventListener('mousemove', handleWorkspaceMouseMove);
            uis.workspace.addEventListener('mouseup', handleDotMouseUp);

            uis.canvasSpace.addEventListener('mousedown', handleCanvasMouseDown);
            uis.canvasSpace.addEventListener('mouseup', handleCanvasMouseUp);
            uis.canvasSpace.addEventListener('mousemove', handleCanvasMouseMove);
            uis.canvasSpace.addEventListener('wheel', handleCanvasWheel);
        } else {
            setTimeout(attach, 400);
        }
    }
    setTimeout(attach, 400);
}

export function dispatchChangeEvent () {
    const imgURI = getScaledDataURI();
    window.editorOnChangeComplete({
        imageURI: imgURI,
    });
}
