import { useState, useEffect } from 'react';
import { logInfo } from '../../../../utils/';
import useImageUpload from '../../../hooks/useImageUploadDynamicStore';
import useImageRenderable from '../../../hooks/useImageRenderable';
import { AssetType } from '../../../../utils/constants';
import { useButtonsStore } from '../../../../store/models';
import { DEFAULT_IMAGE } from '../../../../commons/imageUploader/AdvertisingDefaultImage';

function usePopupLogoUpload (props) {
    const buttonsStore = useButtonsStore();
    const {
        styleData,
        setStyleData,
    } = props;

    const [isUploading, setIsUploading] = useState(false);

    const {
        image: customLogo,
    } = useImageRenderable({
        imageSource: styleData.logo,
        customDefaultImage: DEFAULT_IMAGE,
    });

    const {
        image: landingLogo,
    } = useImageRenderable({
        imageSource: styleData.landingPage.logo,
        customDefaultImage: DEFAULT_IMAGE,
    });

    const {
        image: landingBG,
    } = useImageRenderable({
        imageSource: styleData.landingPage.background,
        customDefaultImage: DEFAULT_IMAGE,
    });

    /*
     *  Asset Type: buttonLogo
     * style.logo
     */

    function setButtonLogoURL (url) {
        logInfo('');
        logInfo('');
        logInfo('');
        logInfo('usePopupLogoUpload().setButtonLogoURL()', url);

        setStyleData({
            ...styleData,
            logo: url,
        });
        setIsUploading(false);
    };
    const [buttonObservable] = useState({});
    const {
        image: buttonLogo,
        upload: uploadButtonLogo,
        imageError: buttonLogoError,
        deleteImage: deleteButtonLogo,
    } = useImageUpload({
        assetType: AssetType.BUTTON_LOGO,
        // observableDataSource: buttonsStore.observed.assets.buttonLogo,
        observableDataSource: buttonObservable,
        consumableDataSource: buttonsStore.assets.buttonLogo,
        customDefaultImage: customLogo,
        setStateFunc: setButtonLogoURL,
        store: buttonsStore,
        uploadMethod: 'uploadWidgetAssets',
        shouldCheckFilesize: true,
        // onFileSizeError: () => {
        //   setButtonLogoErro('File is limited to 102 KB only.');
        // },
        // clearFileSizeError: () => {
        //   setButtonLogoErro('');
        // },
    });

    /*
     *  Asset Type: buttonDirectLink
     *  style.landingPage.background
     */
    function setLandingPageBackgroundURL (url) {
        logInfo('');
        logInfo('');
        logInfo('');
        logInfo('usePopupLogoUpload().setLandingPageBackgroundURL()', url);
        setStyleData({
            ...styleData,
            landingPage: {
                ...styleData.landingPage,
                background: url,
            },
        });
        setIsUploading(false);
    }

    const {
        image: landingPageBackground,
        upload: uploadLandingPageBackground,
        imageError: landingPageBackgroundError,
        deleteImage: deleteLandingPageBackground,
    } = useImageUpload({
        assetType: AssetType.BUTTON_BACKGROUND,
        observableDataSource: buttonsStore.observed.assets.buttonBackground,
        consumableDataSource: buttonsStore.assets.buttonBackground,
        setStateFunc: setLandingPageBackgroundURL,
        customDefaultImage: landingBG,
        store: buttonsStore,
        uploadMethod: 'uploadWidgetAssets',
        shouldCheckFilesize: true,
        sizeLimit: 500,
    });

    /*
     * buttonDirectLink
     * style.landingPage.logo
     */

    function setLandingPageLogoURL (url) {
        logInfo('');
        logInfo('');
        logInfo('');
        logInfo('usePopupLogoUpload().setLandingPageLogoURL()', url);

        setStyleData({
            ...styleData,
            landingPage: {
                ...styleData.landingPage,
                logo: url,
            },
        });
        setIsUploading(false);
    }

    const {
        image: landingPageLogo,
        upload: uploadLandingPageLogo,
        imageError: landingPageLogoError,
        deleteImage: deleteLandingPageLogo,
    } = useImageUpload({
        assetType: AssetType.BUTTON_DIRECT_LINK,
        observableDataSource: buttonsStore.observed.assets.buttonDirectLink,
        consumableDataSource: buttonsStore.assets.buttonDirectLink,
        setStateFunc: setLandingPageLogoURL,
        customDefaultImage: landingLogo,
        store: buttonsStore,
        uploadMethod: 'uploadWidgetAssets',
        shouldCheckFilesize: true,
    });

    function uploadLandingPageLogoWrapper (data) {
        setIsUploading(true);
        uploadLandingPageLogo(data);
    }

    function uploadButtonLogoWrapper (data) {
        setIsUploading(true);
        uploadButtonLogo(data);
    }

    function uploadLandingPageBackgroundWrapper (data) {
        setIsUploading(true);
        uploadLandingPageBackground(data);
    }

    useEffect(() => {
        setIsUploading(false);
    }, [buttonLogoError, landingPageBackgroundError, landingPageLogoError]);

    return {
        buttonLogo,
        uploadButtonLogo: uploadButtonLogoWrapper,
        deleteButtonLogo,

        landingPageBackground,
        uploadLandingPageBackground: uploadLandingPageBackgroundWrapper,
        deleteLandingPageLogo,

        landingPageLogo,
        uploadLandingPageLogo: uploadLandingPageLogoWrapper,
        deleteLandingPageBackground,
        buttonLogoError,
        landingPageLogoError,
        landingPageBackgroundError,

        isUploading,
    };
}

export default usePopupLogoUpload;
