/*
 * engineState.js contains the functions to manage the states of ui, like canvas, image, resizer and resizer dots etc....
 */

/*
 * capture the mouse initial state on mouse down,
 * the eventSource is the origin of the state
 */

const defaultSize = {
    width: 80,
    height: 80,
};

const defaultRectSize = {
    width: 200,
    height: 80,
};

export function defaultState (p) {
    const {
        workspace,
        image,
        shape,
        limits,
    } = p;

    let imageSize = {
        width: defaultSize.width,
        height: defaultSize.height,
    };

    if (shape === 'rect') {
        imageSize = {
            width: defaultRectSize.width,
            height: defaultRectSize.height,
        };
    }

    if (image) {
        if (shape === 'round') {
            imageSize.width = image.height;
            imageSize.height = image.height;
        } else {
            imageSize.width = image.width;
            imageSize.height = image.height;
        }
    }

    // console.log(`defaultState(): shape:${shape} w ${imageSize.width} h ${imageSize.height} `);

    if (imageSize.width <= limits.minCanvasWidth) {
        imageSize.width = limits.minCanvasWidth;
    } else if (imageSize.width >= limits.maxCanvasWidth) {
        imageSize.width = limits.maxCanvasWidth;
    }

    if (imageSize.height <= limits.minCanvasHeight) {
        imageSize.height = limits.minCanvasHeight;
    } else if (imageSize.height >= limits.maxCanvasHeight) {
        imageSize.height = limits.maxCanvasHeight;
    }

    const resizer = center(workspace, imageSize);
    const canvas = center(workspace, imageSize);
    const content = image ? center(resizer, image) : { x: 0, y: 0 };

    return {
        workspace: getRelativeBounds(workspace),
        resizer,
        canvas,
        resizerDots: resizerDotsState(resizer),
        // crosshair: center(resizer, { width: 24, height: 24 }),
        crosshair: { ...canvas },
        content,
    };
}

export function initState (workspace, image, shape) {
    window.editorState = defaultState({
        workspace,
        image,
        shape,
        limits: window.editorLimits,
    });
    window.activeEditorState = null;
}

export function prepareStateEditing (p = {}) {
    const {
        e = null,
        target = null,
    } = p;

    window.activeEditorState = {
        ...window.editorState,
    };

    if (e) {
        window.activeEditorState.mouse = mouseInitialState(e);
    }

    if (target) {
        window.activeEditorState.target = target;
        window.activeEditorState.eventSource = target;
    }

    window.activeEditorState = initStateWithBounds({
        state: window.activeEditorState,
        resizerEl: window.editorUI.resizer,
        canvasEl: window.editorUI.canvas,
    });
    window.activeEditorState.resizerOrigin = window.activeEditorState.resizer;
    window.activeEditorState.contentOrigin = window.activeEditorState.content;
}

export function initContentState (image) {
    if (!image) return;
    window.editorState.content = {
        ...center(
            window.editorState.canvas,
            { width: image.width, height: image.height }
        ),
        scale: 1,
    };
    window.editorUI.image = image;
}

export function mouseInitialState (e) {
    const state = {
        startX: e.clientX,
        startY: e.clientY,
        endX: e.clientX,
        endY: e.clientY,
        deltaX: 0,
        deltaY: 0,
    };
    return state;
}

export function initStateWithBounds (p) {
    const {
        state,
        resizerEl,
        canvasEl,
    } = p;

    const newState = {
        ...state,
        resizer: state.resizer || getRelativeBounds(resizerEl),
        canvas: state.canvas || getRelativeBounds(canvasEl),
        content: state.content || {},
    };
    return newState;
}

/*
 * update the mouse state
 */
export function mouseMoveState (p) {
    const {
        event: e,
        mouse,
        shape,
        target,
    } = p;

    const newMouse = {
        ...mouse,
        endX: e.clientX,
        endY: e.clientY,
        deltaX: e.clientX - mouse.startX,
        deltaY: e.clientY - mouse.startY,
    };

    if (shape === 'round') {
        newMouse.deltaY = newMouse.deltaX;
        if (target === 'top-right' || target === 'bottom-left') {
            newMouse.deltaY = -newMouse.deltaX;
        }
    }
    return newMouse;
}

export function mouseMoveCanvasState (p) {
    const {
        event,
        mouse,
    } = p;

    const newMouse = {
        ...mouse,
        endX: event.clientX,
        endY: event.clientY,
        deltaX: event.clientX - mouse.startX,
        deltaY: event.clientY - mouse.startY,
    };
    return newMouse;
}

/*
 *  parent and child are bounds (vector) props with width, height, x, y,
 */
export function centerChild (parent, child) {
    const x = (parent.width - child.width) / 2;
    const y = (parent.height - child.height) / 2;
    child.x = x;
    child.y = y;
}

export function center (parent, child) {
    const x = (parent.width - child.width) / 2;
    const y = (parent.height - child.height) / 2;
    return {
        ...child,
        x,
        y,
    };
}

export function resizerDotsState (resizer) {
    const dotSize = 12;
    const hs = dotSize / 2;
    const dots = {};

    dots['top-left'] = {
        x: -hs,
        y: -hs,
    };

    dots['top-center'] = {
        x: (resizer.width / 2) - hs,
        y: -hs,
    };

    dots['top-right'] = {
        x: resizer.width - hs,
        y: -hs,
    };

    dots['mid-left'] = {
        x: -hs,
        y: (resizer.height / 2) - hs,
    };

    dots['mid-right'] = {
        x: resizer.width - hs,
        y: (resizer.height / 2) - hs,
    };

    dots['bottom-left'] = {
        x: -hs,
        y: resizer.height - hs,
    };

    dots['bottom-center'] = {
        x: (resizer.width / 2) - hs,
        y: resizer.height - hs,
    };

    dots['bottom-right'] = {
        x: resizer.width - hs,
        y: resizer.height - hs,
    };

    return dots;
}

export function getRelativeBounds (el) {
    if (!el || !el.parentElement) {
        return {
            x: 0,
            y: 0,
            width: el.width,
            height: el.height,
        };
    }
    const elRect = el.getBoundingClientRect();
    const parentRect = el.parentElement.getBoundingClientRect();
    return {
        x: elRect.left - parentRect.left,
        y: elRect.top - parentRect.top,
        width: elRect.width,
        height: elRect.height,
    };
}

export function contentTranslateState (state) {
    const {
        contentOrigin,
        content,
        mouse,
    } = state;

    const newContent = {
        ...content,
        x: contentOrigin.x + mouse.deltaX,
        y: contentOrigin.y + mouse.deltaY,
    };
    return newContent;
}

export function finalizeScaling () {
    window.editorState = {
        ...window.activeEditorState,
    };
}

export function contentScaleState (p) {
    const {
        state,
        event,
        limits,
        ui,
    } = p;

    const newContent = {
        ...state.content,
        scale: state.content.scale + (event.deltaY * limits.scaleChangeRate),
    };

    if (newContent.scale >= limits.maxScale) {
        newContent.scale = limits.maxScale;
    } else if (newContent.scale <= limits.minScale) {
        newContent.scale = limits.minScale;
    }

    const {
        x,
        y,
    } = computeScalePos(state.contentOrigin, newContent.scale, ui.image);

    newContent.x = x;
    newContent.y = y;
    return newContent;
}

export function computeScalePos (origin, scale, image) {
    if (!image) {
        return {
            x: origin.x,
            y: origin.y,
        };
    }

    const ow = image.width * origin.scale;
    const oh = image.height * origin.scale;

    const cx = origin.x + (ow / 2);
    const cy = origin.y + (oh / 2);

    const sw = image.width * scale;
    const sh = image.height * scale;

    const sx = cx - (sw / 2);
    const sy = cy - (sh / 2);
    return {
        x: sx,
        y: sy,
    };
}

export function snapToCenterState (p) {
    const {
        state,
    } = p;
    const canvas = center(state.workspace, state.canvas);
    return {
        canvas,
        resizer: canvas,
        resizerDots: resizerDotsState(canvas),
    };
}
