import useConfirmationDialog from '../../../../commons/dialog/useConfirmationDialog';

function usePlanBannerDialog (props) {
    function contactSupport () {
        window.Intercom('showMessages');
    }

    const {
        openDialog: openPlanBannerDialog,
        renderDialog: renderPlanBannerDialog,
    } = useConfirmationDialog({
        onClose: () => {},
        onConfirm: () => contactSupport(),
        title: 'Upgrade Plan',
        message: 'This feature is not available on your current plan. Please contact ECAL Support to upgrade to a Professional plan.',
        cancelLabel: 'Cancel',
        confirmLabel: 'Yes',
    });

    return {
        openPlanBannerDialog,
        renderPlanBannerDialog,
    };
}

export default usePlanBannerDialog;
