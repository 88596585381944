import React, { useState, useEffect } from 'react';
import {
    createImage,
    AspectRatios,
} from '../../../../utils/image';

import ImageSelectWizard from './imageSelectWizard';
import useImageRenderable from '../../../hooks/useImageRenderable';
import { DEFAULT_IMAGE } from '../../../../commons/imageUploader/AdvertisingDefaultImage';
import DefaultRoundLogo from '../../../../components/ImageCrop/roundLogo';

function useImageSelectWizard (props) {
    const {
        styleData,
        setStyleData,
        imagePropField,
        wizardTitle,
        type,
    } = props;

    const [open, setOpen] = useState(false);
    const [image, setImage] = useState(DEFAULT_IMAGE);
    const [defaultImage, setDefaultImage] = useState(type === 'logo' ? DefaultRoundLogo : DEFAULT_IMAGE);
    const [imageSize, setImageSize] = useState({
        width: '100px',
        height: '100px',
    });

    const {
        image: imageSource,
    } = useImageRenderable({
        imageSource: styleData[imagePropField],
        customDefaultImage: defaultImage,
    });

    useEffect(() => {
        setImage(imageSource[0].data_url);
        computeImage(imageSource[0].data_url);
    }, [imageSource]);

    useEffect(() => {
        if (type === 'logo' && !styleData.logo) {
            setDefaultImage(DefaultRoundLogo);
        }
    }, [type]);

    async function computeImage (imageURL) {
        const img = await createImage(imageURL);
        if (img.aspectRatio === AspectRatios.SQUARE) {
            setImageSize({
                width: '100px',
                height: '100px',
            });
        } else {
            setImageSize({
                width: '150px',
                height: '60px',
            });
        }
    }

    function renderWizard () {
        if (open) {
            return (<ImageSelectWizard
                title={wizardTitle}
                open={open}
                onClose={() => {
                    closeWizard();
                }}
                type={type}
                styleData={styleData}
                setStyleData={setStyleData}
            />);
        }
        return (<React.Fragment></React.Fragment>);
    }

    function openWizard () {
        setOpen(true);
    }

    function closeWizard () {
        setOpen(false);
    }

    return {
        renderWizard,
        openWizard,
        closeWizard,
        image,
        imageSize,
    };
}

export default useImageSelectWizard;
