export async function handleImageSelect (input) {
    return new Promise((resolve) => {
        if (input.files && input.files[0]) {
            const reader = new FileReader();
            reader.onload = function (e) {
                const url = e.target.result;
                resolve(url);
            };
            reader.readAsDataURL(input.files[0]);
        }
    });
}
