
/*
 * call on the useEffects
 */
export function initEditorUI (p) {
    const {
        onUIMounted = () => {},
        shape,
    } = p;
    console.log('initEditorUI(): ', window.editorUI);
    if (window.editorUI) return;

    const workspace = document.querySelector('#editor-workspace');
    const resizer = document.querySelector('#editor-resizer');
    if (workspace && resizer) {
        const dots = resizer.querySelector('#resizer-dots');
        const canvas = document.querySelector('#editor-canvas');
        const canvasSpace = document.querySelector('#editor-canvas-space');
        const crosshair = document.querySelector('#editor-crosshair');
        window.editorUI = {
            workspace,
            canvasSpace,
            canvas,
            resizer,
            dots,
            shape,
            crosshair,
        };
        onUIMounted(window.editorUI);
    } else {
        window.editorUI = null;
        if (window.editorUIRepeat >= 5000) {
            return;
        }
        setTimeout(initEditorUI, 200);
    }
}

export function cleanEditorUI () {
    window.editorUI = null;
}

export function createImage (url) {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener('load', () => {
            resolve({
                image,
                width: image.width,
                height: image.height,
            });
        });
        image.addEventListener('error', (error) => reject(error));
        image.setAttribute('crossOrigin', 'anonymous');
        image.src = url;
    });
}
