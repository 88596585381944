/*
 * the renderer function renders the states of canvas, resizer, image
 */

export function renderUpdates () {
    renderWorkspace(window.activeEditorState, window.editorUI);
}

export function renderWorkspace (states, uis) {
    renderCanvasSpace(states.canvas, uis.canvasSpace, uis.shape);
    renderCanvas(states.canvas, uis.canvas);
    renderCrosshair(states.crosshair, uis.crosshair);
    renderResizer(states.resizer, uis.resizer, uis.dots);
    renderDots(states.resizerDots, uis.resizer);
    renderContent(states, uis);
}

export function renderResizer (state, ui, dots) {
    ui.setAttribute('style', `left: ${state.x}px; top: ${state.y}px; width: ${state.width}px; height: ${state.height}px;`);
    dots.setAttribute('style', `width: ${state.width}px; height: ${state.height}px;`);
}

export function renderCrosshair (state, crosshair) {
    // ui.setAttribute('style', `left: ${state.x}px; top: ${state.y}px;`);
    crosshair.setAttribute('style', `width: ${state.width}px; height: ${state.height}px;`);
    crosshair.width = state.width;
    crosshair.height = state.height;
    const ctx = crosshair.getContext('2d');

    clearContext(crosshair, ctx);
    const centerX = state.width / 2;
    const centerY = state.height / 2;

    const color = '#A3A3A366';
    // Draw the vertical line
    ctx.beginPath();
    ctx.moveTo(centerX, 0);
    ctx.lineTo(centerX, state.height);
    ctx.strokeStyle = color;
    ctx.lineWidth = 2;
    ctx.stroke();

    // Draw the horizontal line
    ctx.beginPath();
    ctx.moveTo(0, centerY);
    ctx.lineTo(state.width, centerY);
    ctx.strokeStyle = color;
    ctx.lineWidth = 2;
    ctx.stroke();
}

export function renderDots (dotStates, ui) {
    for (const dot in dotStates) {
        const state = dotStates[dot];
        renderDot(`.${dot}`, state, ui);
    }
}

export function renderDot (className, state, ui) {
    const dot = ui.querySelector(className);
    if (dot) {
        dot.setAttribute('style', `left: ${state.x}px; top: ${state.y}px;`);
    }
}

export function renderCanvasSpace (state, ui, shape) {
    if (shape === 'round') {
        ui.setAttribute('style', `left: ${state.x}px; top: ${state.y}px; width: ${state.width}px; height: ${state.height}px; border-radius:${state.width / 2}px;`);
    } else {
        ui.setAttribute('style', `left: ${state.x}px; top: ${state.y}px; width: ${state.width}px; height: ${state.height}px;`);
    }
}

export function renderCanvas (state, ui) {
    ui.setAttribute('style', `width: ${state.width}px; height: ${state.height}px;`);
    ui.width = state.width;
    ui.height = state.height;
    // note, canvas width & height is different to canvas.style width...
}

function clearContext (canvas, ctx) {
    ctx.save();
    ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.restore();
}

export function renderContent (states, uis) {
    const content = states.content;
    const image = uis.image;
    const canvasState = states.canvas;
    const canvas = uis.canvas;
    const shape = uis.shape;
    const ctx = canvas.getContext('2d');
    // console.log('renderContent() content ', content);
    if (!ctx || !image || !content) return;
    clearContext(canvasState, ctx);
    // This will handle the clipping
    ctx.save();
    if (shape === 'rect') {
        // clip as rect
        ctx.beginPath();
        ctx.rect(0, 0, canvasState.width, canvasState.height);
        ctx.closePath();
    } else {
        // clip as round
        const centerX = canvasState.width / 2;
        const centerY = canvasState.height / 2;
        const radius = canvasState.width / 2;
        ctx.beginPath();
        ctx.arc(centerX, centerY, radius, 0, Math.PI * 2);
        ctx.closePath();
    }

    ctx.clip();
    ctx.drawImage(
        image,
        content.x, content.y,
        content.width * content.scale, content.height * content.scale);
    ctx.restore();
}
