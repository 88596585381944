import { useState, useEffect } from 'react';
import { DEFAULT_IMAGE } from '../../commons/imageUploader/DefaultImage';
import { aToB, logInfo } from '../../utils/';
const DEFAULT_IMAGE_DATA = [{ data_url: DEFAULT_IMAGE }]; /* THIS IS THE FORMAT ACCEPTED BY THE IMAGE */

/*
 * A Reusable Hook used to upload images and then get its data and serialized for the uploader component.
 */
function useImageUpload (props) {
    const {
        setStateFunc,
        assetType,
        observableDataSource, /* is the data that will be observed by the hook */
        consumableDataSource, /* is the full data that will be consumed by the hook */
        customDefaultImage,
        store,
        uploadMethod,
        urlField = 'fileUrl',
        shouldCheckFilesize = false,
        sizeLimit = 102, // Kilobytes
        // onFileSizeError = () => {},
        // clearFileSizeError = () => {},
        customImageValidation = null,
        waitOnUpload = false,
    } = props;

    const [image, setImage] = useState(customDefaultImage || DEFAULT_IMAGE_DATA);
    const [isUploading, setIsUploading] = useState(false);
    const [imageError, setImageError] = useState('');

    useEffect(() => {
        setIsUploading(false);
        if (consumableDataSource) {
            const url = consumableDataSource[urlField];
            setImage([{ data_url: url }]);
            logInfo('');
            logInfo('');
            logInfo('');
            logInfo('useImageUploadDynamicStore.useEffect.setStateFunc() ', assetType);
            setStateFunc(consumableDataSource[urlField]);
        }
    }, [observableDataSource]);

    useEffect(() => {
        setImage(customDefaultImage);
        setIsUploading(false);
    }, [customDefaultImage]);

    function toDefaultImage () {
        setImage(customDefaultImage || DEFAULT_IMAGE_DATA);
    }
    async function upload (images) {
        console.log('useImageUplaodDyanmicStore.upload()');
        if (customImageValidation && typeof customImageValidation === 'function') {
            const {
                isValid,
                errorMessage,
            } = await customImageValidation({ image: images[0], setImageError });
            if (!isValid) {
                logInfo('will not upload because of image size.....');
                setImageError(errorMessage);
                toDefaultImage();
                return; // will not upload
            }
        }

        if (!isFilesizeValid(images[0])) {
            // if (typeof onFileSizeError === 'function') {
              setImageError(`File is limited to ${sizeLimit || 102} KB only.`);
                // onFileSizeError();
            // }
            toDefaultImage();
            return;
        } else {
          // clearFileSizeError();
        }

        setImageError('');

        if (!images) {
            toDefaultImage();
            return;
        }
        logInfo('');
        logInfo('');
        logInfo('');
        logInfo('XXXXX uploading?? ', { store, uploadMethod, images });
        setIsUploading(true);
        setImage([{ data_url: images[0].data_url }]);
        // better yet show, rotation for image upload
        /* do not set the image url till it come back from the response.
        setStateFunc(images[0].data_url);
        */

        if (typeof store[uploadMethod] === 'function') {
            logInfo('store uploading??', { store, uploadMethod });

            if (waitOnUpload) {
                await store[uploadMethod]({
                    file: images[0].file,
                    assetType,
                });
            } else {
                store[uploadMethod]({
                    file: images[0].file,
                    assetType,
                });
            }
        }
        setIsUploading(false);
    }

    function isFilesizeValid (file) {
        if (!shouldCheckFilesize) {
            return true;
        }

        const base64str = file.data_url.substr(22);
        const decoded = aToB(base64str);
        let fileSize = decoded.length;

        if (file.file && file.file.size) {
            fileSize = file.file.size;
        }

        const kbSize = fileSize / 1024;
        if (kbSize >= sizeLimit) {
            return false;
        }

        return true;
    }

    function deleteImage () {
        setIsUploading(false);
        toDefaultImage();

        logInfo('');
        logInfo('');
        logInfo('');
        logInfo('useImageUploadDynamicStore.deleteImage.setStateFunc() ', assetType);

        setStateFunc('');
    }

    function imageUploadError () {
        setIsUploading(false);
        setImage(DEFAULT_IMAGE_DATA);
        // setStateFunc('');
    }

    return {
        isUploading,
        setIsUploading,
        upload,
        image,
        deleteImage,
        imageUploadError,
        imageError,
        setImageError,
    };
}

export default useImageUpload;
