import { isEmpty } from 'lodash';

export function createImage (url) {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener('load', () => {
            resolve({
                image,
                width: image.width,
                height: image.height,
            });
        });
        image.addEventListener('error', (error) => reject(error));
        image.setAttribute('crossOrigin', 'anonymous');
        image.src = url;
    });
}

export function updateCanvasDimension (canvas) {
    const computedStyle = window.getComputedStyle(canvas);
    canvas.width = Number(computedStyle.width.split('px')[0]);
    canvas.height = Number(computedStyle.height.split('px')[0]);
}

export function positionImage (canvas, image, transform) {
    if (!transform) {
        return { x: 0, y: 0 };
    }
    if (!canvas || !image) {
        return {
            x: transform.x,
            y: transform.y,
        };
    }

    const ccx = canvas.width / 2;
    const ccy = canvas.height / 2;

    const iw = (image.width * transform.scale) / 2;
    const ih = (image.height * transform.scale) / 2;
    const ix = ccx - iw;
    const iy = ccy - ih;
    return {
        x: ix,
        y: iy,
    };
}

function clearContext (canvas, ctx) {
    ctx.save();
    ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.restore();
}

export function renderCanvasContent (props) {
    const {
        image,
        transform,
        shape,
    } = props;

    const canvas = document.querySelector('.canvas-crop');
    const ctx = canvas.getContext('2d');
    if (!ctx || !image) return;

    clearContext(canvas, ctx);
    // This will handle the clipping
    ctx.save();
    if (shape === 'rect') {
        // clip as rect
        ctx.beginPath();
        ctx.rect(0, 0, canvas.width, canvas.height);
        ctx.closePath();
    } else {
        // clip as round
        const centerX = canvas.width / 2;
        const centerY = canvas.height / 2;
        const radius = canvas.width / 2;
        ctx.beginPath();
        ctx.arc(centerX, centerY, radius, 0, Math.PI * 2);
        ctx.closePath();
    }

    ctx.clip(); // everything will be inside the clipping
    ctx.drawImage(
        image,
        transform.x, transform.y,
        image.width * transform.scale, image.height * transform.scale);
    // ctx.scale(transform.scale, transform.scale);
    // ctx.translate(transform.x, transform.y);
    // ctx.drawImage(image, 0, 0);
    // ctx.drawImage(image, transform.x, transform.y);
    ctx.restore();
}

// this function will compute the center position of the image in the screen to center it as it it scaled
function computeScalePos (origin, scale, image) {
    if (!image) {
        return {
            x: origin.x,
            y: origin.y,
        };
    }

    const ow = image.width * origin.scale;
    const oh = image.height * origin.scale;

    const cx = origin.x + (ow / 2);
    const cy = origin.y + (oh / 2);

    const sw = image.width * scale;
    const sh = image.height * scale;

    const sx = cx - (sw / 2);
    const sy = cy - (sh / 2);
    return {
        x: sx,
        y: sy,
    };
}

function captureOrigin (cui) {
    if (!cui.scaleStarted) {
        cui.scaleStarted = true;
        cui.origin = {
            x: cui.transform.x,
            y: cui.transform.y,
            scale: cui.transform.scale,
        };
    }
}

export function updateCropUITransform (transform) {
    window.cropUI = {
        transform: { ...transform },
        wheelDebounceTO: 0,
    };
}

export function attachInteraction (props) {
    const {
        getActiveState,
        onChangeComplete,
    } = props;

    function attach () {
        const {
            transform,
            canvas,
        } = getActiveState();

        const p = canvas;

        window.cropUI = {
           transform: { ...transform },
           wheelDebounceTO: 0,
        };

        p.addEventListener('wheel', (e) => {
            e.stopPropagation();
            e.preventDefault();
            // will update the scale
            const cui = window.cropUI;
            captureOrigin(cui);

            const delta = e.deltaY * 0.001;
            let scale = cui.transform.scale + delta;

            if (scale > 2.5) {
                scale = 2.5;
            }
            if (scale < 0.5) {
                scale = 0.5;
            }

            cui.transform.scale = scale;

            const {
                image,
                canvas,
                shape,
            } = getActiveState();

            const trans = computeScalePos(cui.origin, scale, image);
            cui.transform.x = trans.x;
            cui.transform.y = trans.y;

            renderCanvasContent({
                canvas,
                image,
                transform: cui.transform,
                shape,
            });

            clearTimeout(window.cropUI.wheelDebounceTO);
            function wheelEnded () {
                cui.scaleStarted = false;
                onChangeComplete({
                    transform: window.cropUI.transform,
                    imageURI: canvas.toDataURL('image/png'),
                });
            }
            window.cropUI.wheelDebounceTO = setTimeout(wheelEnded, 400);
        });

        p.addEventListener('mousedown', (e) => {
            const cui = window.cropUI;
            cui.translation = {
                on: true,
                startX: e.screenX,
                startY: e.screenY,
                originX: cui.transform.x,
                originY: cui.transform.y,
            };
        });

        p.addEventListener('mousemove', (e) => {
            const cui = window.cropUI;
            if (!cui.translation?.on) return;
            cui.translation.deltaX = cui.translation.startX - e.screenX;
            cui.translation.deltaY = cui.translation.startY - e.screenY;
            cui.transform.x = (cui.translation.originX - cui.translation.deltaX);
            cui.transform.y = (cui.translation.originY - cui.translation.deltaY);
            const {
                image,
                canvas,
                shape,
            } = getActiveState();

            renderCanvasContent({
                canvas,
                image,
                transform: cui.transform,
                shape,
            });
        });

        p.addEventListener('mouseup', (e) => {
            const cui = window.cropUI;
            if (!cui || isEmpty(cui.translation)) {
                cui.translation = {};
            };
            cui.translation.on = false;
            const {
                canvas,
            } = getActiveState();

            onChangeComplete({
                transform: window.cropUI.transform,
                imageURI: canvas.toDataURL('image/png'),
            });
        });
    }
    setTimeout(attach, 200);
}
