function resizeTL (state) {
    const {
        resizer,
        mouse,
        content,
    } = state;

    const resizerBounds = {
        x: resizer.x + mouse.deltaX,
        y: resizer.y + mouse.deltaY,
        width: resizer.width - mouse.deltaX,
        height: resizer.height - mouse.deltaY,
    };

    const canvasContent = {
        ...content,
    };

    canvasContent.x = canvasContent.x - mouse.deltaX;
    canvasContent.y = canvasContent.y - mouse.deltaY;

    return {
        resizer: resizerBounds,
        content: canvasContent,
    };
}

function resizeTC (state) {
    const {
        resizer,
        mouse,
        content,
    } = state;

    const resizerBounds = {
        x: resizer.x,
        y: resizer.y + mouse.deltaY,
        width: resizer.width,
        height: resizer.height - mouse.deltaY,
    };
    const canvasContent = {
        ...content,
    };
    canvasContent.y = canvasContent.y - mouse.deltaY;
    return {
        resizer: resizerBounds,
        content: canvasContent,
    };
}

function resizeTR (state) {
    const {
        resizer,
        mouse,
        content,
    } = state;

    const resizerBounds = {
        x: resizer.x,
        y: resizer.y + mouse.deltaY,
        width: resizer.width + mouse.deltaX,
        height: resizer.height - mouse.deltaY,
    };
    const canvasContent = {
        ...content,
    };

    canvasContent.y = canvasContent.y - mouse.deltaY;

    return {
        resizer: resizerBounds,
        content: canvasContent,
    };
}

function resizeML (state) {
    const {
        resizer,
        mouse,
        content,
    } = state;

    const resizerBounds = {
        x: resizer.x + mouse.deltaX,
        y: resizer.y,
        width: resizer.width - mouse.deltaX,
        height: resizer.height,
    };
    const canvasContent = {
        ...content,
    };
    canvasContent.x = canvasContent.x - mouse.deltaX;
    return {
        resizer: resizerBounds,
        content: canvasContent,
    };
}

function resizeMR (state) {
    const {
        resizer,
        mouse,
        content,
    } = state;

    const resizerBounds = {
        x: resizer.x,
        y: resizer.y,
        width: resizer.width + mouse.deltaX,
        height: resizer.height,
    };
    const canvasContent = {
        ...content,
    };

    return {
        resizer: resizerBounds,
        content: canvasContent,
    };
}

function resizeBL (state) {
    const {
        resizer,
        mouse,
        content,
    } = state;

    const resizerBounds = {
        x: resizer.x + mouse.deltaX,
        y: resizer.y,
        width: resizer.width - mouse.deltaX,
        height: resizer.height + mouse.deltaY,
    };
    const canvasContent = {
        ...content,
    };

    canvasContent.x = canvasContent.x - mouse.deltaX;

    return {
        resizer: resizerBounds,
        content: canvasContent,
    };
}

function resizeBC (state) {
    const {
        resizer,
        mouse,
        content,
    } = state;

    const resizerBounds = {
        x: resizer.x,
        y: resizer.y,
        width: resizer.width,
        height: resizer.height + mouse.deltaY,
    };
    const canvasContent = {
        ...content,
    };

    return {
        resizer: resizerBounds,
        content: canvasContent,
    };
}

function resizeBR (state) {
    const {
        resizer,
        mouse,
        content,
    } = state;

    const resizerBounds = {
        x: resizer.x,
        y: resizer.y,
        width: resizer.width + mouse.deltaX,
        height: resizer.height + mouse.deltaY,
    };
    const canvasContent = {
        ...content,
    };

    return {
        resizer: resizerBounds,
        content: canvasContent,
    };
}

const stateResizerFuncs = {
    'top-left': resizeTL,
    'top-center': resizeTC,
    'top-right': resizeTR,
    'mid-left': resizeML,
    'mid-right': resizeMR,
    'bottom-left': resizeBL,
    'bottom-center': resizeBC,
    'bottom-right': resizeBR,
};

export function getResizeFunc (target) {
    if (stateResizerFuncs[target] && typeof stateResizerFuncs[target] === 'function') {
        return stateResizerFuncs[target];
    }
    return resizeTL;
}
