import { makeStyles } from '@material-ui/styles';
// import tinycolor from 'tinycolor2';

export default makeStyles(theme => ({

    resizerRoot: {
       display: 'flex',
       width: '100%',
       height: '100%',
       position: 'absolute',
       pointerEvents: 'none',
       boxSizing: 'border-box',
    },

    dots: {
        display: 'block',
        position: 'absolute',
        pointerEvents: 'none',
        border: '2px dashed #AEAEAE',
        boxSizing: 'border-box',
    },

    dot: {
        boxSizing: 'border-box',
        display: 'block',
        position: 'absolute',
        width: '12px',
        height: '12px',
        borderRadius: '6px',
        backgroundColor: '#AEAEAE',
        pointerEvents: 'auto',
        cursor: 'pointer',
    },
}));
