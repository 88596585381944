import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
    workspaceRoot: {
       display: 'block',
       position: 'relative',
       width: '100%',
       height: '100%',
       overflow: 'hidden',
    },

    canvasSpace: ({ shadow }) => {
        const style = {
            boxSizing: 'border-box',
            position: 'absolute',
            display: 'block',
            width: '100%',
            height: '100%',
            cursor: 'move',
            backgroundColor: '#FFFFFF',
            border: '1px solid #A3A3A3',
            overflow: 'visible',
        };

        if (shadow) {
            style.boxShadow = '0px 6px 6px 0px #00000066';
        }

        return style;
    },

    canvas: ({ shape }) => {
        const style = {
            cursor: 'move',
            postion: 'absolute',
            boxSizing: 'border-box',
        };
        return style;
    },

    crosshair: {
        position: 'absolute',
        top: '0px',
        left: '0px',
        color: '#A3A3A3',
        pointerEvent: 'none',
    },

    crossLine: {
        strokeLinecap: 'square',
        strokeWidth: '1',
        stroke: '#A3A3A399',
    },

    resizer: {
        position: 'absolute',
        display: 'block',
    },

}));
