import React from 'react';
import PropTypes from 'prop-types';

import {
    Grid,
    Tabs,
    Tab,
    Tooltip,
} from '@material-ui/core';

import {
    Palette as ColorIcon,
    Collections as GalleryIcon,
    Publish as ImageIcon,
} from '@material-ui/icons';

import {
    ColorPicker,
} from 'material-ui-color';

import {
    Typography,
} from '../../../../components/Wrappers';

import { colorPickerValueToHex } from '../../../../utils';
import { isValidForBanner } from '../../../../utils/defaults';

import useStyles from './banner.styles';
import ImageSelectUI from './imageSelectUI';
import useBannerHook from './useBannerHook';
import BannerGallery from './bannerGallery';
import usePlanBannerDialog from './usePlanBannerDialog';

function Banner (props) {
    const {
        styleData,
        setStyleData,
    } = props;

    const {
        tabValue,
        tabChange,
        bannerSelected,
        setBannerSelected,
        isBannerSelected,
        buttonStore,
        publisher,
    } = useBannerHook({
        styleData,
        setStyleData,
    });

    const {
        openPlanBannerDialog,
        renderPlanBannerDialog,
    } = usePlanBannerDialog({});

    const classes = useStyles({
        width: '100px',
        height: '60px',
    });

    function renderColorPanel () {
        if (tabValue !== 0) {
            return (<React.Fragment></React.Fragment>);
        }
        return (<Grid
            className={classes.tabContent}
        >
            <Grid className={classes.colorPicker} >
                <Typography
                    variant='body1'
                    className={classes.colorPickerText}
                >
                    Banner Colour
                </Typography>
                <ColorPicker
                    value={styleData.bannerColour}
                    defaultValue='#15aae0'
                    onChange={(value) => {
                        setStyleData({
                            ...styleData,
                            bannerColour: colorPickerValueToHex(value),
                        });
                    }}
                    hideTextfield
                    />
            </Grid>
        </Grid>);
    }

    function renderImagePanel () {
        if (tabValue !== 1) {
            return (<React.Fragment></React.Fragment>);
        }
        return (<Grid
                className={classes.tabContent}
            >
            <ImageSelectUI
                styleData={styleData}
                setStyleData={(data) => {
                    if (tabValue === 1) {
                        // console.log('banner.setImageBannerURL(): ', data.bannerURL);
                        buttonStore.setImageBannerURL(data.bannerURL);
                        setStyleData(data);
                    }
                }}
                title="Banner Image"
                wizardTitle={'Banner Image'}
                type="banner"
                imagePropField="bannerURL"
            />
        </Grid>);
    }

    function renderGallery () {
        if (tabValue !== 2) {
            return (<React.Fragment></React.Fragment>);
        }
        return (<Grid
            className={classes.bannerGallery}
        >
            <BannerGallery
                styleData={styleData}
                setStyleData={(data) => {
                    buttonStore.setDynamicBannerURL(data.bannerURL);
                    setStyleData(data);
                }}
                bannerSelected={bannerSelected}
                setBannerSelected={setBannerSelected}
                isBannerSelected={isBannerSelected}
            />
        </Grid>);
    }

    function renderImageIcon () {
        if (!isValidForBanner(publisher)) {
            return (
                <span
                    onClick={() => {
                        openPlanBannerDialog();
                    }}
                >
                    <Tab
                        disabled={true}
                        icon={<ImageIcon />}
                        />
                </span>
            );
        }

        return (<Tooltip title={'Image Banner'}>
            <Tab
                icon={<ImageIcon />}
                />
        </Tooltip>);
    }

    function renderGalleryIcon () {
        if (!isValidForBanner(publisher)) {
            return (
                <span
                    onClick={() => {
                        openPlanBannerDialog();
                    }}
                >
                    <Tab
                        disabled={true}
                        icon={<GalleryIcon />}
                        />
                </span>
            );
        }

        return (<Tooltip title={'Banner Gallery'}>
                <Tab
                    icon={<GalleryIcon />}
                />
            </Tooltip>);
    }

    return (
        <React.Fragment>
            <Grid
                container
                item
                className={classes.sectionRoot}>
                    <Grid className={classes.sectionContent}>
                        <Tabs
                            value={tabValue}
                            onChange={tabChange}
                            classes={{
                                root: classes.tabRoot,
                            }}
                        >
                            <Tooltip title={'Colour Banner'}>
                                <Tab
                                    icon={<ColorIcon />}
                                    />
                            </Tooltip>

                            { renderImageIcon() }
                            { renderGalleryIcon() }

                        </Tabs>
                        { renderColorPanel() }
                        { renderImagePanel() }
                        { renderGallery() }
                    </Grid>
            </Grid>
            { renderPlanBannerDialog() }
        </React.Fragment>
    );
};

Banner.propTypes = {
    setStyleData: PropTypes.func,
    uploadButtonLogo: PropTypes.func,
    styleData: PropTypes.object,

    buttonLogo: PropTypes.any,
    imageError: PropTypes.bool,
    buttonLogoError: PropTypes.string,
    isUploading: PropTypes.bool,
};

Banner.defaultProps = {
    setStyleData: () => {},
    uploadButtonLogo: () => {},
    styleData: {},
    buttonLogo: null,
    imageError: false,
    buttonLogoError: '',
    isUploading: false,
};

export default Banner;
