
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import useStyles from './styles';
import useImageEditor from './useImageEditor';
import ImageResizer from './imageResizer';

function ImageEditor (props) {
    const {
        imageSource,
        onChangeComplete,
        shape,
        open,
        shadow,
    } = props;

    useImageEditor({
        imageSource,
        onChangeComplete,
        shape,
        open,
        shadow,
    });

    const classes = useStyles({
        shape,
        shadow,
    });

    return (
        <div
            id="editor-workspace"
            className={classes.workspaceRoot}
            >
            <div
                id="editor-canvas-space"
                className={classes.canvasSpace}
            >
                <canvas
                    id="editor-canvas"
                    className={classNames(['canvas-crop', classes.canvas])}>

                </canvas>

                <canvas
                    id="editor-crosshair"
                    className={classNames([classes.crosshair])}>

                </canvas>
            </div>

            <ImageResizer
                shape={shape}
            />
        </div>
    );
}

ImageEditor.propTypes = {
    imageSource: PropTypes.any,
    onChangeComplete: PropTypes.func,
    shape: PropTypes.string,
    open: PropTypes.bool,
    shadow: PropTypes.bool,
};

ImageEditor.defaultProps = {
    imageSource: '',
    onChangeComplete: () => {},
    shape: 'round', // values: round, rect
    open: false,
    shadow: true,
};

export default ImageEditor;
