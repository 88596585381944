export const AspectRatios = {
  SQUARE: '1:1',
};

export async function dataURIToFile (dataURI, filename) {
    try {
      const res = await fetch(dataURI);
      const blob = await res.blob();
      const file = new File([blob], filename, { type: blob.type });
      // file.data_url = dataURI;
      return file;
    } catch (err) {
      console.log('Error converting data URI to File:', err);
      return null; // Or handle the error as needed
    }
}

export function createImage (url) {
  return new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => {
          const aratio = getAspectRatio(image.width, image.height);
          resolve({
              image,
              width: image.width,
              height: image.height,
              aspectRatio: aratio,
          });
      });
      image.addEventListener('error', (error) => reject(error));
      image.setAttribute('crossOrigin', 'anonymous');
      image.src = url;
  });
}

export function getAspectRatio (width, height) {
  if (width <= 0 || height <= 0) {
    return 'Invalid dimensions';
  }

  const gcd = (a, b) => {
    return b === 0 ? a : gcd(b, a % b);
  };

  const commonDivisor = gcd(width, height);
  const aspectRatioWidth = width / commonDivisor;
  const aspectRatioHeight = height / commonDivisor;
  return `${aspectRatioWidth}:${aspectRatioHeight}`;
}
