export function queryGetButtons (vars) {
    return {
        query: `query Widgets($i: GetWidgetsInput!  $s: OrderInput , $p: Int, $l: Int) {

            getWidgets( getWidgetsInput:$i, widgetSortInput: $s, page: $p, limit: $l ) {
                totalCount
                edges {
                    node {
                        id
                        draft
                        name
                        image
                        embed {
                            button {
                                js
                                html
                            }
                        }
                        display{
                            text
                            scheduleOption
                            categories 
                            schedules
                            isAutoSubscribe
                            timezone
                            showAllEvents
                            selectAllEvents
                        }
                        style {
                            
                            eventDisplay  {
                                maxHeight
                                minHeight
                                header {
                                    backgroundColour
                                    fontColour
                                    accentColour
                                    bottomBorderColour
                                }
                                sync {
                                    buttonColour
                                    fontColour
                                }
                                primary {
                                    buttonColour
                                    fontColour
                                }
                                secondary {
                                    buttonColour
                                    fontColour
                                }
                            }

                            backgroundColour
                            width
                            fontColour
                            template
                            theme
                        
                            secondaryColour
                            headingColour
                            language
                            logo
                            landingPage {
                                logo
                                background
                            }
                            cssFile
                            bannerType
                            bannerURL
                            bannerColour
                            logoShape
                            logoShadow
                        }
                        subscriberData {
                            customFields {
                                id
                                name
                                value  
                                type  
                                visible
                                required
                                createdDate
                            }
                            registrationFields {
                                email 
                                
                                
                                fullName
                                firstName
                                ageGroup
        
                                mobile 
                                country
                                defaultCountry 
                                
                                dob
                                gender
                                zip
        
                                parentConsentAgeEnabled
                                parentConsentAgeValue
                                
                                requiredAgeEnabled
                                requiredAgeValue
                            }
                        }
                        settings {
                            advertising {
                                banner
                                url
                            }
                            welcomeMessage {
                                active
                                title
                                subTitle
                                message
                            }
                            sharing {
                                active
                                message
                                url
                            }

                            directLinks {
                                socialMediaDirectLink
                                qrCodeDirectLink
                            }
                        }
                        created
                        modified
                    }
                    cursor
                }
                pageInfo {
                  endCursor
                  hasNextPage
                }
            } 
        }
        `,
        variables: vars,
    };
}

export function queryGetEventDisplay (vars) {
    return {
        query: `query Widgets($i: GetWidgetsInput!  $s: OrderInput , $p: Int, $l: Int) {

            getWidgets( getWidgetsInput:$i, widgetSortInput: $s, page: $p, limit: $l ) {
                totalCount
                edges {
                    node {
                        id
                        draft
                        name
                        image
                        embed {
                            event_display {
                                js
                                html
                            }
                        }
                        display{
                            text
                            scheduleOption
                            categories 
                            schedules
                            isAutoSubscribe
                            timezone
                            showAllEvents
                            selectAllEvents
                        }
                        style {
                            
                            eventDisplay  {
                                maxHeight
                                minHeight
                                header {
                                    backgroundColour
                                    fontColour
                                    accentColour
                                    bottomBorderColour
                                }
                                sync {
                                    buttonColour
                                    fontColour
                                }
                                primary {
                                    buttonColour
                                    fontColour
                                }
                                secondary {
                                    buttonColour
                                    fontColour
                                }
                            }

                            backgroundColour
                            width
                            fontColour
                            template
                            theme
                        
                            secondaryColour
                            headingColour
                            language
                            logo
                            landingPage {
                                logo
                                background
                            }
                            cssFile
                            bannerType
                            bannerURL
                            bannerColour
                            logoShape
                            logoShadow
                        }
                        subscriberData {
                            customFields {
                                id
                                name
                                value  
                                type  
                                visible
                                required
                                createdDate
                            }
                            registrationFields {
                                email 
                                
                                
                                fullName
                                firstName
                                ageGroup
        
                                mobile 
                                country
                                defaultCountry 
                                
                                dob
                                gender
                                zip
        
                                parentConsentAgeEnabled
                                parentConsentAgeValue
                                
                                requiredAgeEnabled
                                requiredAgeValue
                            }
                        }
                        settings {
                            advertising {
                                banner
                                url
                            }
                            welcomeMessage {
                                active
                                title
                                subTitle
                                message
                            }
                            sharing {
                                active
                                message
                                url
                            }

                            directLinks {
                                socialMediaDirectLink
                                qrCodeDirectLink
                            }
                        }
                        created
                        modified
                    }
                    cursor
                }
                pageInfo {
                  endCursor
                  hasNextPage
                }
            } 
        }
        `,
        variables: vars,
    };
}

export function queryAddWidget (vars) {
    return {
        query: `mutation Widgets($i: AddWidgetInput) {

            addWidget( addWidgetInput:$i ) {
                totalCount
                edges {
                    node {
                        id
                        draft
                        name
                        image
                        display{
                            text
                            scheduleOption
                            categories 
                            schedules
                            isAutoSubscribe
                            timezone
                        }
                        style {
                        
                            backgroundColour
                            width
                            fontColour
        
                        
                            secondaryColour
                            headingColour
                            language
                            logo
                            landingPage {
                                logo
                                background
                            }
                            cssFile
                        }
                        subscriberData {
                            customFields {
                                id
                                name
                                value  
                                type  
                                visible
                                required
                                createdDate
                            }
                            registrationFields {
                                email 
                                
                                
                                fullName
                                firstName
        
                                mobile 
                                country
                                defaultCountry 
                                
                                dob
                                gender
                                zip
        
                                parentConsentAgeEnabled
                                parentConsentAgeValue
                                
                                requiredAgeEnabled
                                requiredAgeValue
                            }
                        }
                        settings {
                            advertising {
                                banner
                                url
                            }
                            welcomeMessage {
                                active
                                title
                                subTitle
                                message
                            }
                            sharing {
                                active
                                message
                                url
                            }
                        }
                        created
                        modified
                    }
                    cursor
                }
                pageInfo {
                  endCursor
                  hasNextPage
                }
            } 
        }
        `,
        variables: vars,
    };
}

export function queryUpdateWidgets (vars) {
    return {
        query: `mutation Widgets($i: [UpdateWidgetsInput]) {

            updateWidgets (updateWidgetsInput:$i) {
                totalCount
                edges {
                    node {
                        updated
                    }
                    cursor
                }
                pageInfo {
                  endCursor
                  hasNextPage
                }
            } 
        }
        `,
        variables: vars,
    };
}

export function queryDeleteWidgets (vars) {
    return {
        query: `mutation Widgets($d: DeleteWidgetsInput) {

            deleteWidgets( deleteWidgetsInput:$d ) {
                totalCount
                edges {
                    node {
                        id
                        draft
                        name
                        image
                        display{
                            text
                            scheduleOption
                            categories 
                            schedules
                            isAutoSubscribe
                        }
                        style {
                        
                            backgroundColour
                            width
                            fontColour
        
                        
                            secondaryColour
                            headingColour
                            language
                            logo
                            landingPage {
                                logo
                                background
                            }
                            cssFile
                        }
                        subscriberData {
                            customFields {
                                id
                                name
                                value  
                                type  
                                visible
                                required
                                createdDate
                            }
                            registrationFields {
                                email 
                                
                                
                                fullName
                                firstName
        
                                mobile 
                                country
                                defaultCountry 
                                
                                dob
                                gender
                                zip
        
                                parentConsentAgeEnabled
                                parentConsentAgeValue
                                
                                requiredAgeEnabled
                                requiredAgeValue
                            }
                        }
                        settings {
                            advertising {
                                banner
                                url
                            }
                            welcomeMessage {
                                active
                                title
                                subTitle
                                message
                            }
                            sharing {
                                active
                                message
                                url
                            }
                        }
                        created
                        modified
                    }
                    cursor
                }
                pageInfo {
                  endCursor
                  hasNextPage
                }
            } 
        }
        `,
        variables: vars,
    };
}
