import { useState, useEffect } from 'react';
import { AssetType } from '../../../../utils/constants';

import { useButtonsStore } from '../../../../store/models';
import usePopupLogoUpload from './usePopupLogoUpload';

function usePopupDesign (props) {
    const {
        dataSource,
        onDataChange,
        updateSavable,
        sizeLimit = 102, // Kilobytes
    } = props;
    const buttonsStore = useButtonsStore();

    const [styleData, setStyleDataState] = useState({
        ...dataSource.style,
    });

    const [cssFile, setCSSFile] = useState(dataSource.style ? dataSource.style.cssFile : '');
    const [cssFileError, setCssFileError] = useState('');
    useEffect(() => {
        buttonsStore.setStyleSetter(setStyleData);
        buttonsStore.stylesData = { ...dataSource.style };
        return () => {
            if (typeof onDataChange === 'function') {
                onDataChange();
            }
        };
    }, []);

    useEffect(() => {
        buttonsStore.stylesData = { ...dataSource.style };
        setStyleDataState({ ...dataSource.style });
    }, [dataSource]);

    function setStyleData (data) {
        buttonsStore.styleData = {
            ...buttonsStore.styleData,
            ...data,
        };
        console.log('usePopupDesign.setStyleData()  buttonsStore.stylesData -> ', buttonsStore.styleData);
        setStyleDataState(buttonsStore.styleData);
        updateSavable({
            ...dataSource,
            style: { ...buttonsStore.styleData },
        });
    }

    const {
        buttonLogo,
        uploadButtonLogo,
        deleteButtonLogo,

        landingPageBackground,
        uploadLandingPageBackground,
        deleteLandingPageBackground,

        landingPageLogo,
        uploadLandingPageLogo,
        deleteLandingPageLogo,

        buttonLogoError,
        landingPageLogoError,
        landingPageBackgroundError,

        isUploading,
    } = usePopupLogoUpload({
        styleData,
        setStyleData,
    });

    useEffect(() => {
        if (buttonsStore.assets.buttonCss) {
            setCSSFile(buttonsStore.assets.buttonCss.fileUrl);
            setStyleData({
                ...styleData,
                cssFile: buttonsStore.assets.buttonCss.fileUrl,
            });
        }
    }, [buttonsStore.observed.assets.buttonCss]);

    function isFilesizeValid (fileSize) {
      const kbSize = fileSize / 1024;
      if (kbSize >= sizeLimit) {
          return false;
      }

      return true;
    }

    function uploadCSSFile (files) {
      if (!isFilesizeValid(files[0].size)) {
        setCssFileError('File is limited to 102 KB only.');
        return;
      }
        setCssFileError('');
        setCSSFile(files[0].name);
        buttonsStore.uploadWidgetAssets({
            file: files[0],
            assetType: AssetType.BUTTON_CSS,
        });
    }

    return {

        styleData,
        setStyleData,

        buttonLogo,
        uploadButtonLogo,
        deleteButtonLogo,

        landingPageBackground,
        uploadLandingPageBackground,
        deleteLandingPageBackground,

        landingPageLogo,
        uploadLandingPageLogo,
        deleteLandingPageLogo,

        cssFile,
        uploadCSSFile,
        landingPageLogoError,
        landingPageBackgroundError,
        buttonLogoError,
        cssFileError,

        isUploading,
    };
}

export default usePopupDesign;
