import React, { useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
// import PropTypes from 'prop-types';
import {
  Grid,
  Box,
  TablePagination,
} from '@material-ui/core';
// import { Typography } from '../../../components/Wrappers';
import TableContainer from '../../../commons/ECALTable/TableContainer';
import TableControls from '../../../commons/ecal/tableControls';
import DraftLiveBar from '../../../commons/ecal/draftLiveBar';
import Dialog from '../../../commons/dialog/Dialog';
import { Typography } from '../../../components/Wrappers';
import Widget from '../../../components/Widget';
import useButtonNavigation from '../useButtonNavigation';
// import Widget from '../../../components/Widget';

import { useStyles } from './styles';

import {
  columns,
  customCells,
  options,
} from './tableSettings';

import useModuleList from './useModuleList';
import useButtonCodeDialog from './useButtonCodeDialog';
function ButtonsList (props) {
    const classes = useStyles();

    const {
      handleAddDisplay,
    } = useButtonNavigation();

    const {
      progress,
      listData,
      deleteOpen,
      deleteTarget,
      setDeleteOpen,
      setDeleteTarget,
      pageInfo,
      updatePageInfo,

      selectedRows,
      setSelectedRows,
      visibleColumns,
      makeDraft,
      makeLive,
      deleteSingle,
      deleteSelection,
      saveEditData,
      activeAPIKey,
    } = useModuleList({
      columns,
    });

    const renderUniwidget = (activeAPIKey) => {
      /*
      const eCalButton = document.getElementsByClassName('ecal-sync-widget-button');
      setTimeout(() => {
        if (eCalButton.length > 0 && activeAPIKey) {
        window.EcalWidget('destroy');
        window.EcalWidget('boot', { apiKey: activeAPIKey });
        }
      }, 1000);
      */
      setTimeout(() => {
        window.EcalWidget.boot(activeAPIKey);
      }, 1000);
    };

    const {
      renderQRCode,
      renderButtonCode,
      showQRCode,
      showButtonCode,
    } = useButtonCodeDialog({
      activeAPIKey,
      renderUniwidget,
    });

    const onShowButtonCode = useCallback((data) => {
      renderUniwidget(activeAPIKey);
      showButtonCode(data);
    }, [activeAPIKey]);

    const onDelete = useCallback((data) => {
      console.log('Data: ', data);
      setSelectedRows([]);
      renderUniwidget(activeAPIKey);
      setDeleteTarget(data);
      setDeleteOpen(true);
    }, [activeAPIKey]);

    const onShowQRCode = useCallback((data) => {
      renderUniwidget(activeAPIKey);
      showQRCode(data);
    }, [activeAPIKey]);

    const onEdit = useCallback((data) => {
      saveEditData(data);
    }, []);

    const onSelectRow = useCallback((selection) => {
      console.log('selection: ', selection);
      setSelectedRows(selection);
      renderUniwidget(activeAPIKey);
    }, [activeAPIKey]);
    function renderTableBar () {
      console.log('rerendering Table Bar ()');
      if (selectedRows && selectedRows.length > 0) {
        return (
          <DraftLiveBar
            infoText={ selectedRows.length + ' row(s) selected'}
            onLive={() => {
              makeLive();
            }}

            onDraft={() => {
              makeDraft();
            }}

            onDelete={() => {
              deleteSelection();
              setSelectedRows([]);
            }}
          />
        );
      }
      return (
        <TableControls
          hasSearch={false}
          title={'Buttons'}
          excluded={['actions']}
        />
      );
    }

    useEffect(() => {
      renderUniwidget(activeAPIKey);
    }, [progress.buttons, activeAPIKey]);

    return (
    <React.Fragment>

        <Widget
            className={classes.widgetContainer}
            buttonText={'Add'}
            title="Manage Button Displays"
            handleAdd={handleAddDisplay}
            intercomTitleTarget={'manage-button-display'}
            intercomAddTarget='add-button-display'
            inheritHeight disableWidgetMenu>
            <Box
                justifyContent={'space-between'}
                display={'flex'}
                alignItems={'flex-start'}
            >
                <Typography variant='body2'>
                Your button displays allow users to select schedules and sync your events to their calendar.
                </Typography>
            </Box>
        </Widget>
      <Grid item
            xs={12}
            className={classes.tableContainer}
        >
        { renderTableBar() }
        <TableContainer
              hasCheck={true}
              classes={classes}
              className={classes.buttonsTable}
              tableData={listData}
              columns={visibleColumns}
              customCells={customCells}
              tableTitle='Manage Buttons'
              moduleType='button displays'
              showProgress={progress.buttons}
              options={options}
              isInsideWidget={false}
              rowsPerPage={pageInfo.limit}
              removeEmptyRows

              onShowButtonCode={onShowButtonCode}
              onDelete={onDelete}
              onShowQRCode={onShowQRCode}
              onEdit={onEdit}
              onSelectRow={onSelectRow}
            />

        <Grid className={classes.tablePagination}>
          <TablePagination

              rowsPerPageOptions={[
                5,
                10,
                15,
                20,
                // {
                //   value: -1,
                //   label: 'All',
                // },
              ]}

              page={pageInfo.page}
              rowsPerPage={pageInfo.limit}
              count={pageInfo.count}
              labelDisplayedRows={({ from, to, count }) => {
                  return `${from}-${to} of ${count} `;
              }}

              onChangePage={(_, page) => {
                  updatePageInfo({ page });
              }}

              onChangeRowsPerPage={(e) => {
                updatePageInfo({
                  page: 0,
                  limit: e.target.value,
                });
              }}
            />
        </Grid>

      </Grid>

      <Dialog
          openDialog={deleteOpen}
          buttonSecondary={'Cancel'}
          handleAgree={() => {
            deleteSingle(deleteTarget);
            setDeleteOpen(false);
            console.log('Delete Target: ', deleteTarget);
          }}

          closeModal={() => {
            setDeleteOpen(false);
          }}
          title={'Delete Display'}
          dialogContextText={'Are you sure you want to delete this display?'}
        />

      { renderQRCode() }
      { renderButtonCode() }
    </React.Fragment>
  );
}

ButtonsList.propTypes = {
};

ButtonsList.default = {
};

export default observer(ButtonsList);
