import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import useStyles from './styles';
import useCrop from './useCrop';
function ImageCrop (props) {
    const {
        imageSource,
        onChangeComplete,
        shape,
        transform,
    } = props;

    const {
        setCanvasRef,
        setCanvasBox,
    } = useCrop({
        imageSource,
        onChangeComplete,
        shape,
        transform,
    });

    const classes = useStyles({});
    return (
        <div
            ref={(ref) => setCanvasBox(ref)}
            className={classes.root}
            >
            <canvas
                ref={(ref) => setCanvasRef(ref)}
                className={classNames(['canvas-crop', classes.canvas])}>

            </canvas>
        </div>
    );
}

ImageCrop.propTypes = {
    imageSource: PropTypes.any,
    onChangeComplete: PropTypes.func,
    shape: PropTypes.string,
    transform: PropTypes.any,
};

ImageCrop.defaultProps = {
    imageSource: '',
    onChangeComplete: () => {},
    shape: 'round', // values: round, rect
    transform: {},
};

export default ImageCrop;
