import isEmpty from 'lodash';

function defaultBannerURLS () {
    this.imageBannerURL = '';
    this.dynamicBannerURL = '';
}

function setImageBannerURL (url) {
    this.imageBannerURL = url;
}

function setDynamicBannerURL (url) {
    this.dynamicBannerURL = url;
}

function applyDynamicBannerURL (state) {
    const newState = {
        ...state,
        bannerURL: this.dynamicBannerURL,
    };
    this.styleSetter(newState);
}

function applyImageBannerURL (state) {
    const newState = {
        ...state,
        bannerURL: this.imageBannerURL,
    };
    this.styleSetter(newState);
}

function applyBannerImage (state, type) {
    const self = this;
    if (type === 'dynamic') {
        self.applyDynamicBannerURL(state);
    } else if (type === 'image') {
        self.applyImageBannerURL(state);
    } else {
        this.styleSetter({
            ...state,
            bannerURL: '',
            bannerType: type,
            bannerColour: state.bannerColour || this.bannerColorEditOrigin,
        });
    }
}

function setStyleSetter (func) {
    this.styleSetter = func;
}

function withBanner (style) {
    const data = { ...style };
    // console.log('withBanner(): style: ', data);
    this.defaultBannerURLS();
    if (data.bannerType === 'solid') {
        data.bannerURL = '';
        this.bannerColorEditOrigin = data.bannerColour;
    } else if (data.bannerType === 'image') {
        data.bannerColour = '';
        this.imageBannerURL = data.bannerURL;
    } else if (data.bannerType === 'dynamic') {
        data.bannerColour = '';
        this.dynamicBannerURL = data.bannerURL;
    } else if (isEmpty(data.bannerType)) {
        data.bannerType = 'solid';
        data.bannerColour = '#15aae0';
        data.bannerURL = '';
    }
    this.bannerTypeEditOrigin = data.bannerType;
    return data;
}

const setters = {
    setStyleSetter,
    setImageBannerURL,
    setDynamicBannerURL,
    applyDynamicBannerURL,
    applyImageBannerURL,
    applyBannerImage,
    defaultBannerURLS,
    withBanner,
};

export default setters;
