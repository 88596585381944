
export function loadEditData () {
    this.editData = this.env.getPageInfo('admin-widget-edit');
    this.editData.style = this.withBanner(this.editData.style);
}

export function saveEditData (data) {
    this.editData = sanitizeButtonAndAd(data);
    this.editData.style = this.withBanner(this.editData.style);
    console.log('saveEditData(): ', this.editData.style);
    this.env.setPageInfo('admin-widget-edit', this.editData);
}

function sanitizeButtonAndAd (data) {
    const sanitizedData = {
        ...data,
        embed: undefined,

        style: {
            ...data.style,
            eventDisplay: undefined,
        },

        settings: {
            ...data.settings,
            directLinks: undefined,
        },
    };
    return sanitizedData;
}

export function loadEventDisplayData () {
    this.eventDisplayData = this.env.getPageInfo('admin-widget-edit-eventDisplay');
}

export function saveEventDisplayData (data) {
    this.editData = sanitizeEventDisplay(data);
    this.env.setPageInfo('admin-widget-edit-eventDisplay', this.editData);
}

function sanitizeEventDisplay (data) {
    const sanitizedData = {
        ...data,
        embed: undefined,
        settings: {
            ...data.settings,
            directLinks: undefined,
        },
    };
    return sanitizedData;
}
