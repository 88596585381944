import isEmpty from 'lodash';

function hasMatch (str, arr) {
    return arr.some((item) => str.includes(item));
}

export function isValidBannerType (type) {
     const banners = [
        'solid',
        'image',
        'dynamic',
     ];
     return hasMatch(type, banners);
}

export function withDefaultBanners (data) {
    if (!isValidBannerType(data.style.bannerType)) {
        data.style.bannerType = 'solid';
        data.style.bannerURL = '';
        data.style.bannerColour = '#15aae0';
    }

    if (!data.style.logoShape) {
        data.style.logoShape = 'round';
        data.style.logoShadow = true;
    }
}

export function isValidForBanner (publisher) {
    // publisher.planName = 'grow';
    const planName = publisher.planName;
    if (!planName) {
        return false;
    }

    if (planName.toLowerCase().indexOf('enterprise') !== -1 ||
        planName.toLowerCase().indexOf('gameday partner') !== -1 ||
        planName.toLowerCase().indexOf('embed partner') !== -1 ||
        planName.toLowerCase().indexOf('professional') !== -1 ||
        planName.toLowerCase().indexOf('pro') !== -1
    ) {
        return true;
    }
    return false;
}

export function removeBannersPerPlan (publisher, data) {
    if (!isValidForBanner(publisher) && !isEmpty(data.style)) {
        delete data.style?.bannerType;
        delete data.style?.bannerURL;
        delete data.style?.bannerColour;
    }
}
