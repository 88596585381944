import React from 'react';
import PropTypes from 'prop-types';

import {
    Grid,
    Tooltip,
    IconButton,
} from '@material-ui/core';

import {
    PublishOutlined as CreateIcon,
    DeleteOutline as DeleteIcon,
} from '@material-ui/icons';

import {
    Typography,
} from '../../../../components/Wrappers';

import useImageSelectWizard from './useImageSelectWizard';
import useStyles from './imageSelectUI.styles';

function ImageSelectUI (props) {
    const {
        buttonLogoError,
        styleData,
        imagePropField,
        title,
        wizardTitle,
        type,
        setStyleData,
    } = props;

    const {
        renderWizard,
        openWizard,
        image,
        imageSize,
    } = useImageSelectWizard({
        styleData,
        setStyleData,
        imagePropField,
        wizardTitle,
        type,
    });

    const classes = useStyles({
        ...imageSize,
    });

    function handleDelete () {
        if (type === 'logo') {
            setStyleData({
                ...styleData,
                logo: '',
                logoShape: 'round',
                logoShadow: false,
            });
        } else {
            setStyleData({
                ...styleData,
                bannerType: 'solid',
                bannerColour: '#15aae0',
                bannerURL: '',
            });
        }
    }

    return (
        <React.Fragment>
            <Grid className={classes.input}>
                <Grid className={classes.logoTitle}>
                    <Typography variant={'body1'} >
                            { title }
                    </Typography>
                </Grid>

                <Grid
                    container
                    item
                    className={classes.logoSpace}
                    data-intercom-target={'button-design-logo'}
                    >
                        <Grid className={classes.logoDisplay}>
                            <Grid className={classes.imageSpace}>
                                <img
                                    src={image}
                                    className={classes.logoImage}
                                    onClick={() => {
                                        openWizard();
                                    }}
                                />
                            </Grid>

                            <Grid className={classes.imageControls}>
                                <Tooltip title={'Update Image'}>
                                    <IconButton
                                        id="upload-button"
                                        data-ecal-id="upload-button"
                                        data-file="image-file"
                                        color={'primary'}
                                        onClick={() => {
                                            openWizard();
                                        }}
                                        className={classes.iconButton}
                                    >
                                        <CreateIcon fontSize={'medium'} />
                                    </IconButton>
                                </Tooltip>

                                <Tooltip title={'Delete Image'}>
                                    <IconButton
                                        color={'primary'}
                                        onClick={() => {
                                            handleDelete();
                                        }}
                                        className={classes.iconButton}
                                    >
                                        <DeleteIcon fontSize={'medium'} />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>

                        { buttonLogoError &&
                        (
                            <Grid>
                                <Typography
                                    variant={'caption'}
                                    weight={'medium'}
                                    style={{ marginBottom: 30, color: 'red' }}
                                >
                                    {buttonLogoError}
                                </Typography>
                            </Grid>
                        )
                    }
                </Grid>
            </Grid>
            { renderWizard() }
        </React.Fragment>
    );
}

ImageSelectUI.propTypes = {
    setStyleData: PropTypes.func,
    styleData: PropTypes.object,
    buttonLogoError: PropTypes.string,
    title: PropTypes.string,
    imagePropField: PropTypes.string,
    wizardTitle: PropTypes.string,
    type: PropTypes.string,
};

ImageSelectUI.defaultProps = {
    setStyleData: () => {},
    styleData: {},
    buttonLogoError: '',
    title: 'Logo',
    wizardTitle: 'Logo Styles',
    imagePropField: 'logo',
    type: 'logo',
};

export default ImageSelectUI;
