import { useState, useEffect } from 'react';
import {
    attachInteraction,
    renderCanvasContent,
    updateCanvasDimension,
    positionImage,
    updateCropUITransform,
} from './cropEngine';

import {
    createImage,
} from '../../utils/image';

function useCrop (props) {
    const {
        imageSource,
        onChangeComplete: handleChange,
        shape,
        transform: propTransform,
    } = props;

    const [canvas, setCanvasRef] = useState(null);
    const [canvasBox, setCanvasBox] = useState(null);
    const [image, setImage] = useState(null);

    const [transform, setTransform] = useState({ ...propTransform });

    function getActiveState () {
        return {
            image,
            transform,
            canvasBox,
            canvas,
            shape,
        };
    }

    function onChangeComplete (e) {
        setTransform(e.transform);
        handleChange({ ...e });
    }

    useEffect(() => {
        if (canvas) {
            updateCanvasDimension(canvas);
            attachInteraction({
                getActiveState,
                setTransform,
                onChangeComplete,
            });
            setTimeout(autoRender, 400);
        }
    }, [canvas, shape]);

    useEffect(() => {
        updateImageBySource();
    }, [imageSource]);

    useEffect(() => {
        if (image) {
            attachInteraction({
                getActiveState,
                setTransform,
                onChangeComplete,
            });
            setTimeout(autoRender, 200);
        }
    }, [image]);

    function autoRender () {
        const t = positionImage(canvas, image, transform);
        if (!image) return;
        const newTransform = {
            ...transform,
        };
        newTransform.x = t.x;
        newTransform.y = t.y;
        renderCanvasContent({
            image,
            canvas,
            shape,
            transform: newTransform,
        });
        updateCropUITransform(newTransform);
        onChangeComplete({
            imageURI: canvas.toDataURL('image/png'),
            transform: newTransform,
        });
    }

    async function updateImageBySource () {
        if (imageSource) {
            const { image } = await createImage(imageSource);
            setImage(image);
        }
    }

    return {
        setCanvasRef,
        setCanvasBox,
    };
}

export default useCrop;
